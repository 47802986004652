import { createServiceLocatorKey, ServiceLocator } from "@leon-hub/service-locator";
const HTTPS = /* @__PURE__ */ createServiceLocatorKey("HTTPS").withType();
const LOCATION_HREF = /* @__PURE__ */ createServiceLocatorKey("LOCATION_HREF").withType();
const LOCATION_ORIGIN = /* @__PURE__ */ createServiceLocatorKey("LOCATION_ORIGIN").withType();
const LOCATION_HOST = /* @__PURE__ */ createServiceLocatorKey("LOCATION_HOST").withType();
const LOCATION_PATHNAME = /* @__PURE__ */ createServiceLocatorKey("LOCATION_PATHNAME").withType();
const LOCATION_HASH = /* @__PURE__ */ createServiceLocatorKey("LOCATION_HASH").withType();
const LOCATION_SEARCH = /* @__PURE__ */ createServiceLocatorKey("LOCATION_SEARCH").withType();
const REFERRER = /* @__PURE__ */ createServiceLocatorKey("REFERRER").withType();
const COOKIES = /* @__PURE__ */ createServiceLocatorKey("COOKIES").withType();
const USER_AGENT = /* @__PURE__ */ createServiceLocatorKey("USER_AGENT").withType();
const BUILTIN_CONFIG = /* @__PURE__ */ createServiceLocatorKey("BUILTIN_CONFIG").withType();
const QUERY_PARAMS = /* @__PURE__ */ createServiceLocatorKey(
  "QUERY_PARAMS"
).withType();
const ONLINE_STATUS = /* @__PURE__ */ createServiceLocatorKey("ONLINE_STATUS").withType();
const platformKey = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  BUILTIN_CONFIG,
  COOKIES,
  HTTPS,
  LOCATION_HASH,
  LOCATION_HOST,
  LOCATION_HREF,
  LOCATION_ORIGIN,
  LOCATION_PATHNAME,
  LOCATION_SEARCH,
  ONLINE_STATUS,
  QUERY_PARAMS,
  REFERRER,
  USER_AGENT
}, /* @__PURE__ */ (() => Symbol.toStringTag)(), { value: "Module" }));
const serviceLocator = /* @__PURE__ */ new ServiceLocator(/* @__PURE__ */ Object.values(platformKey));
function getLocationQueryParams() {
  return serviceLocator.getProvider(QUERY_PARAMS)();
}
function getLocationSearch() {
  return serviceLocator.getProvider(LOCATION_SEARCH)();
}
function getBuiltinConfig() {
  return serviceLocator.getProvider(BUILTIN_CONFIG)();
}
function getLocationPath() {
  return serviceLocator.getProvider(LOCATION_PATHNAME)();
}
function getLocationHref() {
  return serviceLocator.getProvider(LOCATION_HREF)();
}
function getLocationHash() {
  return serviceLocator.getProvider(LOCATION_HASH)();
}
function getLocationOrigin() {
  return serviceLocator.getProvider(LOCATION_ORIGIN)();
}
function getLocationHost() {
  return serviceLocator.getProvider(LOCATION_HOST)();
}
function getQueryParams() {
  return serviceLocator.getProvider(QUERY_PARAMS)();
}
function getUserAgent() {
  return serviceLocator.getProvider(USER_AGENT)();
}
function getReferer() {
  return serviceLocator.getProvider(REFERRER)();
}
function getCookies() {
  return serviceLocator.getProvider(COOKIES)();
}
function isOnline() {
  return serviceLocator.getProvider(ONLINE_STATUS)();
}
function isHttps() {
  return serviceLocator.getProvider(HTTPS)();
}
export {
  BUILTIN_CONFIG,
  COOKIES,
  HTTPS,
  LOCATION_HASH,
  LOCATION_HOST,
  LOCATION_HREF,
  LOCATION_ORIGIN,
  LOCATION_PATHNAME,
  LOCATION_SEARCH,
  ONLINE_STATUS,
  QUERY_PARAMS,
  REFERRER,
  USER_AGENT,
  getBuiltinConfig,
  getCookies,
  getLocationHash,
  getLocationHost,
  getLocationHref,
  getLocationOrigin,
  getLocationPath,
  getLocationQueryParams,
  getLocationSearch,
  getQueryParams,
  getReferer,
  getUserAgent,
  isHttps,
  isOnline,
  serviceLocator
};
