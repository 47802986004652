import { assert, isObject, isFunction, isString } from "@leon-hub/guards";
import { AbstractErrorCode, AbstractError } from "@leon-hub/errors";
function mergeAppRouteConfigs(defaultAppRouteConfigs, dynamicAppRouteConfigs) {
  const mergedAppRouteConfigs = [];
  for (const defaultAppRouteConfig of defaultAppRouteConfigs) {
    const dynamicAppRouteConfig = dynamicAppRouteConfigs.find(({ name }) => name === defaultAppRouteConfig.name);
    mergedAppRouteConfigs.push(
      dynamicAppRouteConfig ? {
        ...defaultAppRouteConfig,
        path: dynamicAppRouteConfig.path || defaultAppRouteConfig.path,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        metaInfo: dynamicAppRouteConfig.metaInfo || defaultAppRouteConfig.metaInfo,
        seoKey: dynamicAppRouteConfig.seoKey || defaultAppRouteConfig.seoKey
      } : defaultAppRouteConfig
    );
  }
  return mergedAppRouteConfigs;
}
function createRedirectRoutes(redirectTo, redirectsFrom) {
  return redirectsFrom.map((redirectFrom) => ({
    name: `routing-config:${redirectFrom}`,
    path: redirectFrom,
    redirect: { name: redirectTo }
  }));
}
const componentsCache = {};
function getInvalidTypeMessage({ routeName, name, type }) {
  return `Invalid route ([name="${routeName}"]). Expected ${name} to be a type of ${type}`;
}
function createRouteConfig(appRouteConfig, vDynamicComponentComposition, errorCallbackPromise, importCustomVueComponent) {
  const routeComponents = appRouteConfig.components;
  const components = {};
  const props = {};
  assert(isObject(routeComponents), getInvalidTypeMessage({ routeName: appRouteConfig.name, name: "appRouteConfig.components", type: "object" }));
  if (process.env.VUE_APP_ENV_DEV && !isObject(routeComponents)) {
    console.error(getInvalidTypeMessage(
      {
        routeName: appRouteConfig.name,
        name: "appRouteConfig.components",
        type: "object"
      }
    ));
    return null;
  }
  for (const layoutPlaceholderName of Object.keys(routeComponents)) {
    const layoutPlaceholderConfig = routeComponents[layoutPlaceholderName];
    assert(isObject(layoutPlaceholderConfig), getInvalidTypeMessage({
      routeName: appRouteConfig.name,
      name: `appRouteConfig.components[${layoutPlaceholderName}]`,
      type: "object"
    }));
    if (!isObject(layoutPlaceholderConfig)) {
      if (!process.env.VUE_APP_ENV_DEV) {
        console.error(getInvalidTypeMessage({
          routeName: appRouteConfig.name,
          name: `appRouteConfig.components[${layoutPlaceholderName}]`,
          type: "object"
        }));
      }
      continue;
    }
    components[layoutPlaceholderName] = vDynamicComponentComposition;
    const componentPartialOptions = {};
    for (const componentName of Object.keys(layoutPlaceholderConfig)) {
      const componentOption = layoutPlaceholderConfig[componentName];
      assert(componentOption, getInvalidTypeMessage({
        routeName: appRouteConfig.name,
        name: `appRouteConfig.components[${layoutPlaceholderName}][${componentName}]`,
        type: "object"
      }));
      if (!isObject(layoutPlaceholderConfig)) {
        if (process.env.VUE_APP_ENV_DEV) {
          console.error(getInvalidTypeMessage({
            routeName: appRouteConfig.name,
            name: `appRouteConfig.components[${layoutPlaceholderName}][${componentName}]`,
            type: "object"
          }));
        }
        continue;
      }
      let component;
      if (componentOption.component) {
        assert(isObject(componentOption.component) || isFunction(componentOption.component), getInvalidTypeMessage({
          routeName: appRouteConfig.name,
          name: `appRouteConfig.components[${layoutPlaceholderName}].component`,
          type: "object or a function"
        }));
        if (!(isObject(componentOption.component) || isFunction(componentOption.component))) {
          if (!process.env.VUE_APP_ENV_DEV) {
            console.error(getInvalidTypeMessage({
              routeName: appRouteConfig.name,
              name: `appRouteConfig.components[${layoutPlaceholderName}].component`,
              type: "object or a function"
            }));
          }
          continue;
        }
        component = componentOption.component;
      } else {
        assert(isString(componentOption.path), "Expected componentOption.path to be a string");
        const cacheKey = `${layoutPlaceholderName}:${componentName}:${componentOption.path}`;
        if (process.env.VUE_APP_RENDERING_CSR && componentsCache[cacheKey]) {
          component = componentsCache[cacheKey];
        } else {
          component = importCustomVueComponent(componentOption.path, errorCallbackPromise);
          if (process.env.VUE_APP_RENDERING_CSR) {
            componentsCache[cacheKey] = component;
          }
        }
      }
      componentPartialOptions[componentName] = {
        props: componentOption.props,
        order: componentOption.order,
        component
      };
    }
    const keysSorted = Object.keys(componentPartialOptions).sort((a, b) => Number(componentPartialOptions[a].order || 0) - Number(componentPartialOptions[b].order || 0));
    props[layoutPlaceholderName] = {
      components: keysSorted.reduce((accumulator, previous) => {
        accumulator[previous] = componentPartialOptions[previous];
        return accumulator;
      }, {}),
      layoutPlaceholderName
    };
  }
  const result = {
    name: appRouteConfig.name,
    path: appRouteConfig.path,
    meta: appRouteConfig.meta,
    components,
    props
  };
  if (appRouteConfig.alias) {
    result.alias = appRouteConfig.alias;
  }
  return result;
}
function createRouteConfigs(appRouteConfigs, vDynamicComponentComposition, errorCallbackPromise, importCustomVueComponent) {
  const redirectRoutes = [];
  return [
    ...appRouteConfigs.reduce((accumulator, appRouteConfig) => {
      var _a;
      try {
        const item = createRouteConfig(
          appRouteConfig,
          vDynamicComponentComposition,
          errorCallbackPromise,
          importCustomVueComponent
        );
        if (item !== null) {
          accumulator.push(item);
        }
        if ((_a = appRouteConfig.redirectsFrom) == null ? void 0 : _a.length) {
          redirectRoutes.push(...createRedirectRoutes(appRouteConfig.name, appRouteConfig.redirectsFrom));
        }
      } catch (error) {
        const prefix = `Unable to process route [name="${appRouteConfig.name}"]`;
        assert(error instanceof Error, `${prefix}. Unexpected error`);
        error.message = `${prefix}. ${error.message}`;
        console.error(error);
      }
      return accumulator;
    }, []),
    ...redirectRoutes
  ];
}
class ChunkLoadErrorCode extends AbstractErrorCode {
  constructor(code = "CHUNK_LOAD_ERROR") {
    super(code);
  }
}
const chunkLoadErrorCode = /* @__PURE__ */ new ChunkLoadErrorCode();
class ChunkLoadError extends AbstractError {
  constructor(options) {
    super({
      ...options,
      code: chunkLoadErrorCode
    });
    this.chunkPath = options.chunkPath;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getSerializable() {
    return {
      ...super.getSerializable(),
      chunkPath: this.chunkPath
    };
  }
}
var AppRouteTransitionName = /* @__PURE__ */ ((AppRouteTransitionName2) => {
  AppRouteTransitionName2["OVERLAY"] = "overlay";
  return AppRouteTransitionName2;
})(AppRouteTransitionName || {});
var CustomerRouteAccessRole = /* @__PURE__ */ ((CustomerRouteAccessRole2) => {
  CustomerRouteAccessRole2["AUTHORIZED"] = "authorized";
  CustomerRouteAccessRole2["ANONYMOUS"] = "anonymous";
  CustomerRouteAccessRole2["FORBIDDEN"] = "forbidden";
  return CustomerRouteAccessRole2;
})(CustomerRouteAccessRole || {});
function isCustomerRouteAccessRole(value) {
  return Object.values(CustomerRouteAccessRole).some((role) => role === value);
}
function isMetaPropertyWithName(value) {
  if (isCoreMetaProperty(value)) {
    return typeof value.name === "string";
  }
  return false;
}
function isScriptMetaProperty(value) {
  return isCoreMetaProperty(value) && isString(value.type);
}
function isCoreMetaProperty(value) {
  return isObject(value) && Object.values(value).every((val) => val === void 0 || typeof val === "string");
}
function isTitleMetaProperty(value) {
  return isCoreMetaProperty(value);
}
const layoutPlaceholderNames = /* @__PURE__ */ Object.freeze([
  "header",
  "top",
  "default",
  "topBar",
  "leftSideBar",
  "rightSideBar",
  "footer",
  "sideBar"
]);
function isLayoutPlaceholderName(value) {
  return layoutPlaceholderNames.some((name) => name === value);
}
export {
  AppRouteTransitionName,
  ChunkLoadError,
  CustomerRouteAccessRole,
  createRouteConfigs,
  isCustomerRouteAccessRole,
  isLayoutPlaceholderName,
  isMetaPropertyWithName,
  isScriptMetaProperty,
  isTitleMetaProperty,
  mergeAppRouteConfigs
};
