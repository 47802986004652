import { ScriptError } from "@leon-hub/app-errors";
var Events = /* @__PURE__ */ ((Events2) => {
  Events2["SERVICE_WORKER_REGISTERED"] = "swRegistered";
  Events2["Z_APP_ERROR"] = "zAppError";
  Events2["Z_BET_ERROR"] = "zBetError";
  Events2["Z_TRY_DEPOSITE"] = "zTryDeposite";
  Events2["Z_FAST_BETS_CLICKED"] = "zFastBetClicked";
  Events2["Z_OPEN_LIVE_CHAT"] = "zOpenLiveChat";
  Events2["Z_DEP_ICON_CLICK"] = "zDepIconClick";
  Events2["Z_DEP_ICON_NEW_CLICK"] = "zDepIconNewClick";
  Events2["Z_LIVE_TRACKER"] = "zLivetracker";
  Events2["Z_LIVE_SCORE_BOARD_CLICK"] = "zLiveScoreboardClick";
  Events2["Z_MAKE_DEPOSITE"] = "zMakeDeposite";
  Events2["Z_MAKE_DEP_DEPOSITE"] = "zMakeDepConfirm";
  Events2["Z_MAKE_INT_DEPOSITE"] = "zMakeInitDep";
  Events2["Z_SIMPLE_IND_OPEN"] = "zSimpleIndopen";
  Events2["Z_SIMPLE_IND_SENT"] = "zSimpleIndsent";
  Events2["Z_SIMPLE_IND_OK"] = "zSimpleIndOK";
  Events2["Z_WITHDRAWAL_REQUEST"] = "zWithdrawalRequest";
  Events2["Z_WITHDRAWAL_CANCEL_DEPOSIT_FORM"] = "zWithdrawalCancelDepositForm";
  Events2["Z_SUBMIT_SLIP_OK"] = "zSubmitSlipOK";
  Events2["Z_SUBMIT_SLIP"] = "zSubmitSlip";
  Events2["Z_FAIL_REGISTER"] = "zFailRegister";
  Events2["Z_DO_REGISTER_OK"] = "zDoRegisterOK";
  Events2["Z_DO_REGISTER"] = "zDoRegister";
  Events2["Z_LOGGED_IN"] = "zLoggedIn";
  Events2["Z_SESSION_EXPIRED"] = "zSessionExpired";
  Events2["Z_OPEN_GAME"] = "zOpenGame";
  Events2["Z_OPEN_DEMO_GAME"] = "zOpenDemoGame";
  Events2["Z_MAKE_GAME_DEPOSIT_OK"] = "zMakeGameDepositOK";
  Events2["Z_MAKE_GAME_WITHDRAW_OK"] = "zMakeGameWithdrawOK";
  Events2["Z_GET_NATIVE_APP"] = "zGetNativeApp";
  Events2["Z_GET_PWA"] = "zGetPWA";
  Events2["Z_CLOSE_GET_NATIVE_APP"] = "zCloseGetNativeApp";
  Events2["Z_PUSH_OPEN"] = "zPushOpen";
  Events2["Z_OPEN_NEW_TICKET"] = "zOpenNewTicket";
  Events2["Z_OPEN_SUPPORT_PAGE"] = "zOpenSupportPage";
  Events2["Z_INDEX_VIEW_TAP"] = "zIndexviewtap";
  Events2["Z_GO_TO_MOBILE_4_FROM_MOBILE_5"] = "zGoToMobile4FromMobile5";
  Events2["Z_PAYMENT_VIEW_TAP"] = "zPaymentviewtap";
  Events2["Z_FTD_FOR_USER"] = "zFTDforUser";
  Events2["Z_LI_REGISTRATION_OK"] = "zLIRegistrationOk";
  Events2["Z_LI_REGISTRATION_ERROR"] = "zLIRegistrationError";
  Events2["Z_REGISTRATION"] = "zRegistration";
  Events2["Z_REGISTRATION_PROCESS"] = "zRegistrationProcess";
  Events2["Z_REGISTRATION_SUCCESS"] = "zRegistrationSuccess";
  Events2["Z_REGISTRATION_ERROR"] = "zRegistrationError";
  Events2["Z_USER_FEEDBACK"] = "zUserFeedback";
  Events2["USER_ACTIONS"] = "userActions";
  Events2["Z_INIT"] = "zInit";
  Events2["Z_OPEN_PROMO_PAGE"] = "zOpenPromoPage";
  Events2["Z_IDENT_SUCCESS"] = "zIdentSuccess";
  Events2["Z_IDENT_FAIL"] = "zIdentFail";
  Events2["Z_CASH_OUT"] = "zCashOut";
  Events2["MODAL_ERROR_MESSAGES"] = "ModalErrorMessages";
  Events2["CLICK_MAP"] = "zClickMap";
  Events2["Z_CLICK_JACKPOT_BANNER"] = "zClickJackpotBanner";
  Events2["Z_PARTICIPATION_IN_PROMO"] = "zParticipationInPromo";
  Events2["Z_PUSH_CLICK"] = "zPushClick";
  Events2["Z_PUSH_DISPLAY"] = "zPushDisplay";
  Events2["Z_PHONE_CHECK"] = "zPhoneCheck";
  Events2["Z_BONUS_CODE"] = "zBonusCode";
  Events2["Z_IFRAME_CLICK"] = "ziFrameClick";
  Events2["Z_SEARCH"] = "zSearch";
  Events2["Z_SEARCH_SPORT"] = "zSearchSport";
  Events2["Z_SEARCH_BY_PROVIDER"] = "zSearchByProvider";
  Events2["Z_SEARCH_GAME"] = "zSearchGame";
  Events2["Z_OPEN_GAME_LOBBY"] = "zOpenGameLobby";
  Events2["Z_CLICK_DEPOSIT"] = "zСlickDeposit";
  Events2["Z_OPEN_DEPOSIT_METHOD"] = "zOpenDepositMethod";
  Events2["Z_DURATION_REGISTRATION"] = "zDurationRegistration";
  Events2["Z_CLOSE_PREVIEW_GAME"] = "zClosePreviewGame";
  Events2["Z_BONUS_REJECTION"] = "zBonusRejection";
  Events2["Z_AB_TEST_NEW_USERS"] = "zABTestNewUsers";
  Events2["Z_VISIT_BONUSES_PAGE"] = "zVisitBonusesPage";
  Events2["Z_SWIPE_GAMES"] = "zSwipeGames";
  Events2["Z_CAMPAIGN_REJECTION"] = "zCampaignRejection";
  Events2["Z_SUPER_LIVE_BANNER"] = "zSuperLiveBanner";
  Events2["Z_VIRTUAL_SPORT_BANNER"] = "zVirtualSportBanner";
  Events2["Z_PROGRESS_LOYALTY_PROGRAM"] = "zProgressLoyaltyProgram";
  Events2["Z_LOYALTY_PROGRAM_ONBOARDING"] = "zLoyaltyProgramOnboarding";
  Events2["Z_LOYALTY_PROGRAM_INFO"] = "zLoyaltyProgramInfo";
  Events2["Z_ONBOARDING"] = "zOnboarding";
  Events2["Z_HIDE_BALANCE"] = "zHideBalance";
  Events2["Z_LOADER_LOADING"] = "zLoaderLoading";
  Events2["Z_LOADER_CLOSE"] = "zLoaderClose";
  Events2["Z_STATISTICS_BUTTON"] = "zStatisticsButton";
  Events2["Z_CLICK_NON_ACTIVE_DEPOSIT_BUTTON"] = "zClickNonActiveDepositButton";
  Events2["Z_REFER_FRIEND"] = "zReferFriend";
  Events2["Z_UPDATE_CORDOVA_APP"] = "zUpdateCordovaApp";
  Events2["Z_WITHDRAWAL_WAGER_NO"] = "zWithdrawalWagerNo";
  Events2["Z_WITHDRAWAL_WAGER_YES"] = "zWithdrawalWagerYes";
  Events2["Z_MODAL_WITHDRAWAL_WAGER_CLICKS"] = "zModalWithdrawalWagerClicks";
  Events2["Z_OPEN_CORDOVA_FIRST_TIME"] = "zOpenCordovaFirstTime";
  Events2["Z_PREDEFINED_SUM_DEPOSIT"] = "zPredefinedSumDeposit";
  Events2["Z_AUTH_VISIT_PAGE_EURO"] = "zAuthVisitPageEuro";
  Events2["Z_NON_AUTH_VISIT_PAGE_EURO"] = "zNonAuthVisitPageEuro";
  Events2["Z_GET_CARD_EURO"] = "zGetCardEuro";
  Events2["Z_MISCLICK_BATTLE_PASS_EURO"] = "zMisclickBattlePassEuro";
  Events2["Z_PROMO_CONDITIONS_EURO"] = "zPromoConditionsEuro";
  Events2["Z_MY_BONUSES_EURO"] = "zMyBonusesEuro";
  Events2["Z_MISCLICK_CARD_EURO"] = "zMisclickCardEuro";
  Events2["Z_AUTH_VIEW_OFFERS_EURO"] = "zAuthViewOffersEuro";
  Events2["Z_AUTH_PARTICIPATE_EURO"] = "zAuthParticipateEuro";
  Events2["Z_MISCLICK_LEADER_BOARD"] = "zMisclickLeaderBoard";
  Events2["Z_CLICK_ARROW_TOURNAMENT"] = "zClickArrowTournament";
  Events2["Z_LOGIN_MAIN_EURO"] = "zLogInMainEuro";
  Events2["Z_LOGIN_BATTLE_PASS_EURO"] = "zLogInBattlePassEuro";
  Events2["Z_LOGIN_TOURNAMENT_EURO"] = "zLogInTournamentEuro";
  Events2["Z_GET_PRIZE_BATTLE_PASS_EURO"] = "zGetPrizeBattlePassEuro";
  Events2["Z_NON_AUTH_NO_CLICKS"] = "zNonAuthNoClicks";
  Events2["Z_AUTH_NO_CLICKS"] = "zAuthNoClicks";
  Events2["Z_BONUS_USAGE_EURO"] = "zBonusUsageEuro";
  Events2["Z_MY_COLLECTION_EURO"] = "zMyCollectionEuro";
  Events2["Z_MISCLICK_MY_COLLECTION_EURO"] = "zMisclickMyCollectionEuro";
  Events2["Z_MY_COLLECTION_DURATION_EURO"] = "zMyCollectionDurationEuro";
  Events2["Z_MY_COUPONS_EURO"] = "zMyСouponsEuro";
  Events2["Z_MISCLICK_MY_COUPONS_EURO"] = "zMisclickMyСouponsEuro";
  Events2["Z_MISCLICK_PRIZE_DRAWING_EURO"] = "zMisclickPrizeDrawingEuro";
  Events2["Z_DETAILS_RAFFLE_PRIZES_EURO"] = "zDetailsRafflePrizesEuro";
  Events2["Z_FOLLOW_BONUSES"] = "zFollowBonuses";
  Events2["Z_CLICK_TO_OPEN_VIP"] = "zClickToOpenVIP";
  Events2["Z_AUTH_VISIT_VIP"] = "zAuthVisitVIP";
  Events2["Z_NON_AUTH_VISIT_VIP"] = "zNonAuthVisitVIP";
  Events2["Z_AUTH_BECOME_VIP_BUTTON"] = "zAuthBecomeVIPButton";
  Events2["Z_NON_AUTH_BECOME_VIP_BUTTON"] = "zNonAuthBecomeVIPButton";
  Events2["Z_AUTH_CONTACT_US_BUTTON"] = "zAuthContactUsButton";
  Events2["Z_NON_AUTH_CONTACT_US_BUTTON"] = "zNonAuthContactUsButton";
  Events2["Z_FAQ_ARTICLE_VIP"] = "zFAQarticleVIP";
  Events2["Z_ADVANTAGES_TAB_VIP"] = "zAdvantagesTabVIP";
  Events2["Z_MISCLICK_ADVANTAGES"] = "zMisClickAdvantages";
  Events2["Z_WITHDRAWAL_CANCELLATION_POPUP"] = "zWithdrawalCancellationPopUp";
  Events2["Z_WITHDRAWAL_CANCELLATION_CLICK"] = "zWithdrawalCancellationClick";
  Events2["ADVENT_RECEIVE"] = "advent_receive";
  Events2["CUSTOMER_REGISTERED"] = "customerRegistered";
  Events2["ADVENT_OBTAIN_REWARD"] = "advent_obtain_reward";
  Events2["ADVENT_APPLIED_REWARD"] = "advent_applied_reward";
  Events2["BET_SHARE"] = "bet_share";
  Events2["BET_SHARE_COPY_LINK"] = "bet_copy";
  Events2["BET_SHARE_ERROR"] = "bet_share_error";
  Events2["SHARED_BET_LOAD"] = "bet_load";
  Events2["SHARED_BET_ERROR"] = "bet_load_error";
  Events2["NEW_HOMEPAGE_VIEW"] = "new_homepage_view";
  Events2["WITHDRAWAL_CANCELLATION_POPUP"] = "withdrawalCancellationPopUp";
  Events2["WITHDRAWAL_CANCELLATION"] = "withdrawalCancellation";
  return Events2;
})(Events || {});
var TargetParamWithdrawal = /* @__PURE__ */ ((TargetParamWithdrawal2) => {
  TargetParamWithdrawal2["USER_PROFILE_HISTORY"] = "userProfileHistoryWithdrawals";
  TargetParamWithdrawal2["USER_PROFILE"] = "userProfileWithdrawal";
  TargetParamWithdrawal2["DEPOSIT_LINK"] = "depositListWithdrawalLink";
  return TargetParamWithdrawal2;
})(TargetParamWithdrawal || {});
var TargetParamHistory = /* @__PURE__ */ ((TargetParamHistory2) => {
  TargetParamHistory2["BET_SLIP"] = "betSlipHistory";
  TargetParamHistory2["REQUEST_WITHDRAWAL"] = "requestWithdrawalHistory";
  TargetParamHistory2["USER_PROFILE"] = "userProfileHistory";
  return TargetParamHistory2;
})(TargetParamHistory || {});
var TargetParamEnter = /* @__PURE__ */ ((TargetParamEnter2) => {
  TargetParamEnter2["HEADER"] = "headerEnter";
  TargetParamEnter2["SIDE_MENU"] = "sideMenuEnter";
  TargetParamEnter2["BOTTOM_LOW_PROFILE"] = "bottomLowProfileEnter";
  TargetParamEnter2["BOTTOM_LOW_BALANCE"] = "bottomLowBalanceEnter";
  TargetParamEnter2["BET_SLIP"] = "betSlipLogIn";
  TargetParamEnter2["REGISTRATION"] = "registrationEnter";
  TargetParamEnter2["SESSION_EXPIRED"] = "sessionExpiredEnter";
  TargetParamEnter2["SESSION_EXPIRED_NOTIFICATION"] = "sessionExpiredNotificationEnter";
  TargetParamEnter2["EMAIL_LOGIN_PAGE"] = "emailLoginPage";
  TargetParamEnter2["FREE_BET_NEW_USER"] = "freeBetNewUsersEnter";
  TargetParamEnter2["PROMO_PAGE"] = "promoPageEnter";
  return TargetParamEnter2;
})(TargetParamEnter || {});
var TargetParamNavigation = /* @__PURE__ */ ((TargetParamNavigation2) => {
  TargetParamNavigation2["BUTTON_LOW_SLOTS"] = "buttonLowSlots";
  TargetParamNavigation2["SIDE_MENU_SLOTS"] = "sideMenuSlots";
  TargetParamNavigation2["SIDE_MENU_LIVE"] = "sideMenuLive";
  TargetParamNavigation2["SIDE_MENU_VIRTUAL_SPORTS"] = "sideMenuVirtualSports";
  TargetParamNavigation2["HEADER_VIRTUAL_SPORTS"] = "headerVirtualSports";
  TargetParamNavigation2["HEADER_LIVE"] = "headerLive";
  TargetParamNavigation2["HEADER_SLOTS"] = "headerSlots";
  TargetParamNavigation2["LINK_IN_PROFILE"] = "linkInProfile";
  return TargetParamNavigation2;
})(TargetParamNavigation || {});
const YA_METRIKA_API_URL = "https://mc.yandex.ru/metrika/tag.js";
const defaultOptions = {
  collectQueues: true,
  sourceUrl: YA_METRIKA_API_URL,
  developMode: /* @__PURE__ */ (() => process.env.NODE_ENV)() !== "production"
};
let EVENTS_QUEUE = [];
const addEvent = (event) => EVENTS_QUEUE.push(event);
const getEvents = () => [...EVENTS_QUEUE];
const clear$1 = () => {
  EVENTS_QUEUE = [];
};
const run$1 = (callback) => {
  for (const { eventName, parameters } of EVENTS_QUEUE) {
    callback(eventName, parameters);
  }
  clear$1();
};
const EventsQueue = {
  addEvent,
  getEvents,
  run: run$1,
  clear: clear$1
};
let PARAMS_QUEUE = [];
const addParameter = (parameters) => PARAMS_QUEUE.push(parameters);
const getParameters = () => [...PARAMS_QUEUE];
const clear = () => {
  PARAMS_QUEUE = [];
};
const run = (callback) => {
  for (const parameters of PARAMS_QUEUE) {
    callback(parameters);
  }
  clear();
};
const ParametersQueue = {
  addParameter,
  getParameters,
  run,
  clear
};
const prefix = "[yandex-metrika]";
const info = (message = "", parameters) => {
  console.info(`${prefix} ${message}`, parameters || "");
};
const error = (message = "", parameters = {}) => {
  console.error(`${prefix} ${message}`, parameters);
};
const silent = (...parameters) => {
  if (process.env.VUE_APP_RENDERING_CSR) {
    console.info(prefix, parameters);
  }
};
const debug = { info, error, silent };
async function loadAppendScript(sourceUrl) {
  return new Promise((resolve, reject) => {
    if (!window.yandex_metrika_callbacks2) {
      window.yandex_metrika_callbacks2 = [];
    }
    window.yandex_metrika_callbacks2.push(() => {
      resolve();
    });
    const head = document.head || document.querySelectorAll("head")[0];
    const script = document.createElement("script");
    script.src = sourceUrl;
    script.defer = true;
    script.addEventListener("error", (error2) => {
      var _a;
      return reject(
        new ScriptError({
          filename: error2.filename ?? ((_a = error2.target) == null ? void 0 : _a.src),
          message: error2.message ?? "Possible cause: blocked by an ad blocker"
        })
      );
    });
    head.append(script);
  });
}
let INSTANCE = null;
let OPTIONS = defaultOptions;
const mergeOptions = (options) => ({ ...defaultOptions, ...options });
const setDefaultOptions$1 = (options) => {
  OPTIONS = { ...OPTIONS, ...options };
};
const triggerEvent$1 = (eventName, parameters = {}) => {
  if (INSTANCE) {
    if (OPTIONS.developMode) {
      debug.info(`trigger event ${eventName}:`, parameters);
    } else {
      INSTANCE.reachGoal(eventName, parameters);
    }
  } else if (OPTIONS.collectQueues) {
    EventsQueue.addEvent({ eventName, parameters });
  }
};
const triggerParameters$1 = (parameters) => {
  if (INSTANCE) {
    if (OPTIONS.developMode) {
      debug.info("trigger params:", parameters);
    } else {
      INSTANCE.params(parameters);
    }
  } else if (OPTIONS.collectQueues) {
    ParametersQueue.addParameter(parameters);
  }
};
const createYandexMetrika = async (sourceUrl, props) => {
  await loadAppendScript(sourceUrl);
  const YaMetrika = window.Ya.Metrika2;
  return new YaMetrika(props);
};
const register$1 = async (config, options = {}) => {
  if (INSTANCE) {
    debug.error("instance already exist");
  }
  OPTIONS = mergeOptions(options);
  const { sourceUrl = defaultOptions.sourceUrl } = OPTIONS;
  INSTANCE = await createYandexMetrika(sourceUrl, config);
  if (OPTIONS.developMode) {
    debug.info("registration success");
  }
  if (OPTIONS.collectQueues) {
    EventsQueue.run(triggerEvent$1);
    ParametersQueue.run(triggerParameters$1);
  }
};
const unregister$1 = () => {
  EventsQueue.clear();
  ParametersQueue.clear();
  INSTANCE = null;
};
function getInstanceClient() {
  return {
    register: register$1,
    unregister: unregister$1,
    triggerEvent: triggerEvent$1,
    setDefaultOptions: setDefaultOptions$1,
    triggerParams: triggerParameters$1,
    getEvents: EventsQueue.getEvents,
    getParams: ParametersQueue.getParameters
  };
}
const unregister = () => {
};
const register = async (config, options = {}) => {
  debug.silent(config, options);
};
const triggerEvent = (eventName, parameters = {}) => {
  debug.silent(eventName, parameters);
};
const triggerParameters = (parameters = {}) => {
  debug.silent(parameters);
};
const setDefaultOptions = (options = {}) => {
  debug.silent(options);
};
function getInstanceServer() {
  return {
    register,
    unregister,
    triggerEvent,
    setDefaultOptions,
    triggerParams: triggerParameters,
    getEvents: () => [],
    getParams: () => []
  };
}
function provideInstance() {
  return process.env.VUE_APP_RENDERING_SSR ? getInstanceServer() : getInstanceClient();
}
let instance = null;
function getYMInstance() {
  if (instance)
    return instance;
  instance = provideInstance();
  return instance;
}
export {
  Events,
  TargetParamEnter,
  TargetParamHistory,
  TargetParamNavigation,
  TargetParamWithdrawal,
  getYMInstance
};
