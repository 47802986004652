import { isValidInterface, isBoolean, isOptionalNumber, isObjectValues, isString, assert } from "@leon-hub/guards";
import { Theme } from "@leon-hub/api-sdk";
function isBuiltinConfig(target) {
  return typeof target === "undefined" || isValidInterface(target, {
    isNT: isBoolean,
    isQueryHashingEnabled: isBoolean,
    time: isOptionalNumber,
    docParseTime: isOptionalNumber,
    theme: (value) => isObjectValues([isString], value),
    adPaths: (value) => isObjectValues([isString], value)
  });
}
const defaultConfig = {
  isNT: /* @__PURE__ */ (() => process.env.NODE_ENV)() === "development",
  isQueryHashingEnabled: false,
  theme: { default: /* @__PURE__ */ (() => Theme.DARK)() },
  adPaths: {}
};
const getBuiltinConfig = () => {
  const config = typeof window === "undefined" ? defaultConfig : window.initConfig || defaultConfig;
  assert(isBuiltinConfig(config), `Invalid builtin config: ${JSON.stringify(config)}`);
  return config;
};
export {
  defaultConfig,
  getBuiltinConfig,
  isBuiltinConfig
};
