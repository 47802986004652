import { normalizeError } from "@leon-hub/errors";
import { promiseTimeout } from "@leon-hub/utils";
import { isAdvancedHttpRequestsEnabled, makeCordovaHttpRequest } from "@leon-hub/cordova";
async function doFetch(url, options, timeout, onError, controller, preparedRequestCallback) {
  const abortController = controller ?? new AbortController();
  let isAborted = false;
  abortController.signal.addEventListener("abort", () => {
    isAborted = true;
  });
  const request = Object.assign(
    new Request(url, {
      ...options,
      signal: abortController.signal
    }),
    {
      toJSON: () => ({
        url,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        headers: Object.fromEntries(request.headers.entries()),
        body: options.body
      })
    }
  );
  if (preparedRequestCallback) {
    preparedRequestCallback(request);
  }
  if (process.env.VUE_APP_PLATFORM_CORDOVA && await isAdvancedHttpRequestsEnabled()) {
    return makeCordovaHttpRequest(
      request.url,
      request.method,
      Object.fromEntries(request.headers.entries()),
      options.body,
      timeout,
      abortController,
      onError
    );
  }
  return promiseTimeout({
    timeout: timeout ?? 15e3,
    promise: fetch(request),
    error: onError ? (originalError) => onError(normalizeError(originalError), request) : void 0,
    onTimeout: () => isAborted || abortController.abort()
  });
}
export {
  doFetch
};
