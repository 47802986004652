import { AbstractErrorCode, AbstractError } from "@leon-hub/errors";
import { isError } from "@leon-hub/guards";
const _AppErrorCode = class _AppErrorCode extends AbstractErrorCode {
  constructor(code) {
    super(code);
  }
};
_AppErrorCode.APP_UNEXPECTED_ERROR = /* @__PURE__ */ new _AppErrorCode("UNEXPECTED_ERROR");
_AppErrorCode.APP_IGNORED_ERROR = /* @__PURE__ */ new _AppErrorCode("APP_IGNORED_ERROR");
_AppErrorCode.SCRIPT_ERROR = /* @__PURE__ */ new _AppErrorCode("SCRIPT_ERROR");
let AppErrorCode = _AppErrorCode;
class AppError extends AbstractError {
  constructor(options) {
    super({
      ...options,
      code: (options == null ? void 0 : options.code) ?? AppErrorCode.APP_UNEXPECTED_ERROR
    });
  }
}
class AppIgnoredError extends AppError {
  constructor() {
    super({
      message: "Ignored Error",
      code: AppErrorCode.APP_IGNORED_ERROR,
      silent: true
    });
  }
}
class ComponentError extends AbstractError {
  constructor(options) {
    var _a;
    super({
      ...options,
      message: (options == null ? void 0 : options.prefix) ? `${options.prefix}: ${((_a = options.originalError) == null ? void 0 : _a.message) ?? (options == null ? void 0 : options.message) ?? "Component Error"}` : (options == null ? void 0 : options.message) ?? "Component Error",
      code: new AppErrorCode("COMPONENT_ERROR")
    });
    if (!options) return;
    const vm = options.vm ?? null;
    if (vm == null ? void 0 : vm.$props) {
      this.addLogMetaData("props", vm.$props);
      this.addLogMetaData("info", options.info);
      this.addLogMetaData("name", vm.$options.name);
    }
  }
}
class ScriptError extends AbstractError {
  constructor(options) {
    super({
      ...options,
      message: `Script failed to load: filename=${options.filename}; message=${options.message}`,
      code: AppErrorCode.SCRIPT_ERROR
    });
  }
}
class UnhandledRejection extends AbstractError {
  constructor({ event, ...options }) {
    var _a;
    const { reason } = event;
    super({
      ...options,
      code: new AppErrorCode("UNHANDLED_REJECTION"),
      interactive: event.isTrusted,
      originalError: isError(reason) ? reason : void 0
    });
    this.addLogMetaData("eventTimeStamp", event.timeStamp);
    if (typeof window !== "undefined") {
      this.addLogMetaData("isRootTarget", event.target === ((_a = window.event) == null ? void 0 : _a.target));
    }
    if (!isError(reason)) {
      this.addLogMetaData("reason", reason);
    }
  }
}
class ComponentWarning extends AbstractError {
  constructor(options) {
    super({
      ...options,
      code: new AppErrorCode("COMPONENT_WARNING")
    });
    const vm = options.vm ?? null;
    if (vm) {
      this.addLogMetaData("props", vm.$props);
      this.addLogMetaData("name", vm.$options.name);
    }
    this.addLogMetaData("trace", options.trace);
  }
}
export {
  AppError,
  AppErrorCode,
  AppIgnoredError,
  ComponentError,
  ComponentWarning,
  ScriptError,
  UnhandledRejection
};
