import { AbstractErrorCode, AbstractError, normalizeError } from "@leon-hub/errors";
import { UnknownRequestHashExceptionCode, RequestHashingDisabledExceptionCode, AccessDeniedRemoteApiExceptionCode, PreviousRequestHasNotExpiredExceptionCode, RequestOptionsPriority, InvalidCodeExceptionCode, SessionExceptionCode, RemoteApiErrorExceptionCode, G2SVRequiredExceptionCode } from "@leon-hub/api-sdk";
import { getLocationOrigin, getCookies, getLocationPath, getLocationHost, getQueryParams, getReferer, getBuiltinConfig } from "@leon-hub/service-locator-env";
import { doFetch } from "@leon-hub/fetch-client";
import { LogLevel, logger } from "@leon-hub/logging";
import { assert, isObject as isObject$3, isNumber, isString, isArrayOf, isOptionalString, isArrayOfStrings, isValidObject, isOptionalNumber } from "@leon-hub/guards";
import debug from "debug";
import { IndexedDb } from "@leon-hub/indexed-db";
import { memoize, serializeHeaders, getConflictingKeys, Deferred, getUuid, mergeQueries, trim, Json, sleep } from "@leon-hub/utils";
import { getBootstrapTranslations } from "@leon-hub/bootstrap-translations";
import { getCordovaAppConfig } from "@leon-hub/cordova";
import { isDebugEnabled } from "@leon-hub/debug";
import { EditionValueRenderingPrerender } from "@leon-hub/environment-editions";
import { getPrerenderIp, QueryParameterName } from "@leon-hub/query-manager";
import * as crc32 from "crc-32";
import { ChunkLoadError } from "@leon-hub/routing-config";
const _ApiErrorCode = class _ApiErrorCode extends AbstractErrorCode {
};
_ApiErrorCode.API_UNEXPECTED_ERROR = /* @__PURE__ */ new _ApiErrorCode("UNEXPECTED_ERROR");
_ApiErrorCode.API_TECHNICAL_ERROR = /* @__PURE__ */ new _ApiErrorCode("TECHNICAL_ERROR");
_ApiErrorCode.API_SERVICE_UNAVAILABLE_ERROR_503 = /* @__PURE__ */ new _ApiErrorCode("SERVICE_UNAVAILABLE_ERROR_503");
_ApiErrorCode.API_CONNECTION_ERROR = /* @__PURE__ */ new _ApiErrorCode("CONNECTION_ERROR");
_ApiErrorCode.API_REQUEST_ABORTED_ERROR = /* @__PURE__ */ new _ApiErrorCode("REQUEST_ABORTED");
_ApiErrorCode.API_IP_BLOCKED_ERROR = /* @__PURE__ */ new _ApiErrorCode("IP_BLOCKED");
_ApiErrorCode.API_EVENT_MISSING_ERROR = /* @__PURE__ */ new _ApiErrorCode("MISSING");
_ApiErrorCode.API_URL_NOT_FOUND = /* @__PURE__ */ new _ApiErrorCode("API_URL_NOT_FOUND");
_ApiErrorCode.API_UNKNOWN_HASH = /* @__PURE__ */ new _ApiErrorCode(
  /* @__PURE__ */ (() => UnknownRequestHashExceptionCode.UNKNOWN_REQUEST_HASH)()
);
_ApiErrorCode.API_REQUEST_HASHING_IS_DISABLED = /* @__PURE__ */ new _ApiErrorCode(
  /* @__PURE__ */ (() => RequestHashingDisabledExceptionCode.REQUEST_HASHING_IS_DISABLED)()
);
_ApiErrorCode.API_ACCESS_DENIED = /* @__PURE__ */ new _ApiErrorCode(
  /* @__PURE__ */ (() => AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED)()
);
_ApiErrorCode.API_PREVIOUS_REQUEST_HAS_NOT_EXPIRED = /* @__PURE__ */ new _ApiErrorCode(
  /* @__PURE__ */ (() => PreviousRequestHasNotExpiredExceptionCode.PREVIOUS_REQUEST_HAS_NOT_EXPIRED)()
);
let ApiErrorCode = _ApiErrorCode;
class ApiError extends AbstractError {
  constructor(options) {
    super({
      ...options,
      code: (options == null ? void 0 : options.code) ?? ApiErrorCode.API_UNEXPECTED_ERROR
    });
    this.responseCode = (options == null ? void 0 : options.responseCode) ?? 0;
    this.request = options == null ? void 0 : options.request;
    this.response = options == null ? void 0 : options.response;
    this.operationName = options == null ? void 0 : options.operationName;
  }
  setOperationName(operationName) {
    this.operationName = operationName;
  }
  getOperationName() {
    return this.operationName;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getSerializable() {
    return {
      ...super.getSerializable(),
      responseCode: this.responseCode,
      request: this.request,
      response: this.response,
      operationName: this.operationName
    };
  }
}
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
function getDefaultExportFromCjs(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
}
function isObject$2(value) {
  var type = typeof value;
  return value != null && (type == "object" || type == "function");
}
var isObject_1 = isObject$2;
var freeGlobal$1 = typeof commonjsGlobal == "object" && commonjsGlobal && /* @__PURE__ */ (() => commonjsGlobal.Object)() === Object && commonjsGlobal;
var _freeGlobal = freeGlobal$1;
var freeGlobal = _freeGlobal;
var freeSelf = typeof self == "object" && self && /* @__PURE__ */ (() => self.Object)() === Object && self;
var root$2 = freeGlobal || freeSelf || /* @__PURE__ */ Function("return this")();
var _root = root$2;
var root$1 = _root;
var now$1 = function() {
  return root$1.Date.now();
};
var now_1 = now$1;
var reWhitespace = /\s/;
function trimmedEndIndex$1(string) {
  var index = string.length;
  while (index-- && reWhitespace.test(string.charAt(index))) {
  }
  return index;
}
var _trimmedEndIndex = trimmedEndIndex$1;
var trimmedEndIndex = _trimmedEndIndex;
var reTrimStart = /^\s+/;
function baseTrim$1(string) {
  return string ? string.slice(0, trimmedEndIndex(string) + 1).replace(reTrimStart, "") : string;
}
var _baseTrim = baseTrim$1;
var root = _root;
var Symbol$3 = /* @__PURE__ */ (() => root.Symbol)();
var _Symbol = Symbol$3;
var Symbol$2 = _Symbol;
var objectProto$1 = /* @__PURE__ */ (() => Object.prototype)();
var hasOwnProperty = /* @__PURE__ */ (() => objectProto$1.hasOwnProperty)();
var nativeObjectToString$1 = /* @__PURE__ */ (() => objectProto$1.toString)();
var symToStringTag$1 = Symbol$2 ? /* @__PURE__ */ (() => Symbol$2.toStringTag)() : void 0;
function getRawTag$1(value) {
  var isOwn = hasOwnProperty.call(value, symToStringTag$1), tag = value[symToStringTag$1];
  try {
    value[symToStringTag$1] = void 0;
    var unmasked = true;
  } catch (e) {
  }
  var result = nativeObjectToString$1.call(value);
  if (unmasked) {
    if (isOwn) {
      value[symToStringTag$1] = tag;
    } else {
      delete value[symToStringTag$1];
    }
  }
  return result;
}
var _getRawTag = getRawTag$1;
var objectProto = /* @__PURE__ */ (() => Object.prototype)();
var nativeObjectToString = /* @__PURE__ */ (() => objectProto.toString)();
function objectToString$1(value) {
  return nativeObjectToString.call(value);
}
var _objectToString = objectToString$1;
var Symbol$1 = _Symbol, getRawTag = _getRawTag, objectToString = _objectToString;
var nullTag = "[object Null]", undefinedTag = "[object Undefined]";
var symToStringTag = Symbol$1 ? /* @__PURE__ */ (() => Symbol$1.toStringTag)() : void 0;
function baseGetTag$1(value) {
  if (value == null) {
    return value === void 0 ? undefinedTag : nullTag;
  }
  return symToStringTag && symToStringTag in Object(value) ? getRawTag(value) : objectToString(value);
}
var _baseGetTag = baseGetTag$1;
function isObjectLike$1(value) {
  return value != null && typeof value == "object";
}
var isObjectLike_1 = isObjectLike$1;
var baseGetTag = _baseGetTag, isObjectLike = isObjectLike_1;
var symbolTag = "[object Symbol]";
function isSymbol$1(value) {
  return typeof value == "symbol" || isObjectLike(value) && baseGetTag(value) == symbolTag;
}
var isSymbol_1 = isSymbol$1;
var baseTrim = _baseTrim, isObject$1 = isObject_1, isSymbol = isSymbol_1;
var NAN = 0 / 0;
var reIsBadHex = /^[-+]0x[0-9a-f]+$/i;
var reIsBinary = /^0b[01]+$/i;
var reIsOctal = /^0o[0-7]+$/i;
var freeParseInt = parseInt;
function toNumber$1(value) {
  if (typeof value == "number") {
    return value;
  }
  if (isSymbol(value)) {
    return NAN;
  }
  if (isObject$1(value)) {
    var other = typeof value.valueOf == "function" ? value.valueOf() : value;
    value = isObject$1(other) ? other + "" : other;
  }
  if (typeof value != "string") {
    return value === 0 ? value : +value;
  }
  value = baseTrim(value);
  var isBinary = reIsBinary.test(value);
  return isBinary || reIsOctal.test(value) ? freeParseInt(value.slice(2), isBinary ? 2 : 8) : reIsBadHex.test(value) ? NAN : +value;
}
var toNumber_1 = toNumber$1;
var isObject = isObject_1, now = now_1, toNumber = toNumber_1;
var FUNC_ERROR_TEXT = "Expected a function";
var nativeMax = /* @__PURE__ */ (() => Math.max)(), nativeMin = /* @__PURE__ */ (() => Math.min)();
function debounce(func, wait, options) {
  var lastArgs, lastThis, maxWait, result, timerId, lastCallTime, lastInvokeTime = 0, leading = false, maxing = false, trailing = true;
  if (typeof func != "function") {
    throw new TypeError(FUNC_ERROR_TEXT);
  }
  wait = toNumber(wait) || 0;
  if (isObject(options)) {
    leading = !!options.leading;
    maxing = "maxWait" in options;
    maxWait = maxing ? nativeMax(toNumber(options.maxWait) || 0, wait) : maxWait;
    trailing = "trailing" in options ? !!options.trailing : trailing;
  }
  function invokeFunc(time) {
    var args = lastArgs, thisArg = lastThis;
    lastArgs = lastThis = void 0;
    lastInvokeTime = time;
    result = func.apply(thisArg, args);
    return result;
  }
  function leadingEdge(time) {
    lastInvokeTime = time;
    timerId = setTimeout(timerExpired, wait);
    return leading ? invokeFunc(time) : result;
  }
  function remainingWait(time) {
    var timeSinceLastCall = time - lastCallTime, timeSinceLastInvoke = time - lastInvokeTime, timeWaiting = wait - timeSinceLastCall;
    return maxing ? nativeMin(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting;
  }
  function shouldInvoke(time) {
    var timeSinceLastCall = time - lastCallTime, timeSinceLastInvoke = time - lastInvokeTime;
    return lastCallTime === void 0 || timeSinceLastCall >= wait || timeSinceLastCall < 0 || maxing && timeSinceLastInvoke >= maxWait;
  }
  function timerExpired() {
    var time = now();
    if (shouldInvoke(time)) {
      return trailingEdge(time);
    }
    timerId = setTimeout(timerExpired, remainingWait(time));
  }
  function trailingEdge(time) {
    timerId = void 0;
    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    lastArgs = lastThis = void 0;
    return result;
  }
  function cancel() {
    if (timerId !== void 0) {
      clearTimeout(timerId);
    }
    lastInvokeTime = 0;
    lastArgs = lastCallTime = lastThis = timerId = void 0;
  }
  function flush() {
    return timerId === void 0 ? result : trailingEdge(now());
  }
  function debounced() {
    var time = now(), isInvoking = shouldInvoke(time);
    lastArgs = arguments;
    lastThis = this;
    lastCallTime = time;
    if (isInvoking) {
      if (timerId === void 0) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        clearTimeout(timerId);
        timerId = setTimeout(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === void 0) {
      timerId = setTimeout(timerExpired, wait);
    }
    return result;
  }
  debounced.cancel = cancel;
  debounced.flush = flush;
  return debounced;
}
var debounce_1 = debounce;
const debounce$1 = /* @__PURE__ */ getDefaultExportFromCjs(debounce_1);
class GqlApiErrorCode extends ApiErrorCode {
}
const _GqlApiResponseErrorCode = class _GqlApiResponseErrorCode extends GqlApiErrorCode {
  constructor(code) {
    super(code);
  }
};
_GqlApiResponseErrorCode.GQL_API_SERVICE_INVALID_SYNTAX = /* @__PURE__ */ new _GqlApiResponseErrorCode("InvalidSyntax");
_GqlApiResponseErrorCode.GQL_API_SERVICE_VALIDATION_ERROR = /* @__PURE__ */ new _GqlApiResponseErrorCode("ValidationError");
_GqlApiResponseErrorCode.GQL_API_SERVICE_DATA_FETCHING_EXCEPTION = /* @__PURE__ */ new _GqlApiResponseErrorCode("DataFetchingException");
_GqlApiResponseErrorCode.GQL_API_SERVICE_OPERATION_NOT_SUPPORTED = /* @__PURE__ */ new _GqlApiResponseErrorCode("OperationNotSupported");
_GqlApiResponseErrorCode.GQL_API_SERVICE_EXECUTION_ABORTED = /* @__PURE__ */ new _GqlApiResponseErrorCode("ExecutionAborted");
let GqlApiResponseErrorCode = _GqlApiResponseErrorCode;
class GqlApiError extends ApiError {
  constructor(options) {
    super({
      ...options,
      code: options == null ? void 0 : options.code
    });
    this.batch = options == null ? void 0 : options.batch;
    this.subRequest = options == null ? void 0 : options.subRequest;
    this.responseItem = options == null ? void 0 : options.responseItem;
  }
}
class GqlApiBatchedSubRequestError extends GqlApiError {
  constructor(options) {
    const { extensions } = options;
    const errorCode = options.code ?? new GqlApiResponseErrorCode(extensions.errorCode);
    super({
      ...options,
      code: errorCode,
      message: options.message || extensions.message || void 0
    });
    this.extensions = extensions;
  }
  isMutationRequest() {
    var _a;
    return !!((_a = this.subRequest) == null ? void 0 : _a.mutation);
  }
}
class GqlApiPreviousRequestHasNotExpired extends GqlApiBatchedSubRequestError {
  constructor(options) {
    super({
      ...options,
      code: ApiErrorCode.API_PREVIOUS_REQUEST_HAS_NOT_EXPIRED
    });
  }
}
class GqlApiPromotionNotFoundError extends GqlApiBatchedSubRequestError {
  constructor(options) {
    super({
      ...options
    });
  }
}
class GqlApiBatchError extends GqlApiError {
  constructor(options) {
    super({ ...options });
  }
}
class GqlApiBatchUnknownHashError extends GqlApiBatchError {
  constructor(options) {
    super({
      ...options,
      code: ApiErrorCode.API_UNKNOWN_HASH
    });
    this.requestId = options.requestId;
  }
}
class GqlApiBatchHashingDisabledError extends GqlApiBatchError {
  constructor(options) {
    super({
      ...options,
      code: ApiErrorCode.API_REQUEST_HASHING_IS_DISABLED
    });
  }
}
const defaultCustomHeaders = {};
const DEFAULT_BASE_URL = "";
class BaseClient {
  constructor(options) {
    this.debug = debug("client");
    this.customHeaders = {};
    this.connectionErrorResolver = null;
    this.cookies = "";
    this.getHeaders = memoize((customHeaders = defaultCustomHeaders) => {
      const allHeaders = {
        ...this.headers,
        ...this.customHeaders,
        ...customHeaders
      };
      if (this.cookies) {
        allHeaders.cookie = this.cookies;
      }
      return Object.keys(allHeaders).filter((key, index, list) => key && allHeaders[key] && list.indexOf(key) === index).reduce((accumulator, key) => {
        const value = allHeaders[key];
        return value ? {
          ...accumulator,
          [key.toLowerCase()]: value
        } : accumulator;
      }, {});
    });
    this.defaultCacheTTL = 0;
    this.getCacheDb = memoize(() => new IndexedDb({ dbName: "cache", storeName: "api" }));
    this.baseUrl = options.baseUrl ?? DEFAULT_BASE_URL;
    this.origin = options.origin ?? getLocationOrigin().replace(/\/+$/, "");
    if (this.baseUrl.startsWith("http")) {
      this.origin = "";
    }
    this.method = options.method;
    this.headers = options.headers ?? {};
    this.credentials = options.credentials ?? "include";
    this.cookies = getCookies();
  }
  setConnectionErrorResolver(value) {
    this.connectionErrorResolver = value;
  }
  // eslint-disable-next-line rulesdir/class-method-use-this-regex,class-methods-use-this
  get bootstrapTranslations() {
    return getBootstrapTranslations();
  }
  getCredentials() {
    return this.credentials;
  }
  getBaseUrl() {
    return this.baseUrl;
  }
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl.replace(/\/+$/, "");
  }
  getOrigin() {
    return this.origin;
  }
  getCustomHeaders() {
    return this.customHeaders;
  }
  getCustomHeader() {
    return this.customHeaders;
  }
  setCustomHeaders(headers) {
    this.customHeaders = {
      ...this.customHeaders,
      ...headers
    };
  }
  getDefaultMethod() {
    return this.method;
  }
  setHeaders(headers) {
    Object.assign(this.headers, headers);
  }
  setDefaultCacheTtl(ttl) {
    this.defaultCacheTTL = ttl;
  }
  async setCache(cacheKey, value, ttl) {
    const isRationalNumber = Number.isFinite(ttl) && ttl > 0;
    return this.getCacheDb().setItem(cacheKey, {
      expires: isRationalNumber ? Date.now() + ttl : 0,
      value
    });
  }
  async getCache(cacheKey) {
    const cacheItem = await this.getCacheDb().getItem(cacheKey);
    if (!cacheItem || Date.now() > (cacheItem.expires || Infinity)) {
      return void 0;
    }
    return cacheItem.value;
  }
}
const languageHeaderName = "X-APP-LANGUAGE";
const themeHeaderName = "X-APP-THEME";
function getBaseHeaders(headers = {}) {
  const baseHeaders = {
    // Builtin headers
    "X-APP-PLATFORM": process.env.VUE_APP_PLATFORM,
    "X-APP-RENDERING": process.env.VUE_APP_RENDERING,
    "X-APP-MODERNITY": process.env.VUE_APP_MODERNITY,
    "X-APP-ENV": process.env.VUE_APP_ENV,
    "X-APP-SKIN": process.env.VUE_APP_SKIN,
    "X-APP-LAYOUT": process.env.VUE_APP_LAYOUT,
    "X-APP-OS": process.env.VUE_APP_OS,
    "X-APP-BROWSER": process.env.VUE_APP_BROWSER,
    "X-APP-VERSION": process.env.VUE_APP_VERSION,
    ...headers
  };
  if (process.env.VUE_APP_PLATFORM_WEB) {
    Object.assign(baseHeaders, {
      "X-REQUESTED-URI": getLocationPath()
    });
    if (process.env.VUE_APP_PRERENDER) {
      const prerenderHeaders = {
        "X-USER-HOST": getLocationHost(),
        "X-USER-IP": getPrerenderIp(),
        "X-APP-RENDERING": EditionValueRenderingPrerender
      };
      Object.assign(baseHeaders, prerenderHeaders);
    }
    const runtimePlatform = getQueryParams()[QueryParameterName.PLATFORM];
    if (runtimePlatform) {
      Object.assign(baseHeaders, {
        "X-APP-PLATFORM": runtimePlatform
      });
    }
  }
  if (process.env.VUE_APP_RENDERING_SSR) {
    const ssrHeaders = {
      "X-USER-HOST": getLocationHost()
    };
    Object.assign(baseHeaders, ssrHeaders);
  }
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    const { apkVersion, appBuildId, appStore } = getCordovaAppConfig();
    const cordovaHeaders = {
      "X-APP-APK-VERSION": apkVersion,
      "X-APP-BUILD-ID": appBuildId,
      "X-APP-STORE": appStore
    };
    Object.assign(baseHeaders, cordovaHeaders);
  } else {
    Object.assign(baseHeaders, {
      "X-APP-REFERRER": getReferer()
    });
  }
  if (isDebugEnabled()) {
    Object.assign(baseHeaders, {
      "X-APP-DEBUG": "1"
    });
  }
  return baseHeaders;
}
const MAX_MESSAGE_LENGTH = 200;
function getObjectDescription(item, { prefix } = {}) {
  assert(isObject$3(item));
  return Object.entries(item).filter(([, value]) => value !== void 0).map(([key, value]) => `${prefix || ""}${key}: ${isObject$3(value) ? JSON.stringify(value, null, 2).slice().slice(0, MAX_MESSAGE_LENGTH) : value}`).join("\n");
}
function getResponseDescription(response) {
  return getObjectDescription({
    url: response.url,
    status: response.status,
    statusText: response.statusText,
    headers: serializeHeaders([...response.headers.entries()])
  }, { prefix: "response " });
}
class GqlApiG2svRequiredError extends GqlApiBatchedSubRequestError {
  constructor(options) {
    const { extensions } = options;
    super({
      ...options
    });
    this.extensions = extensions;
  }
}
class ApiServiceUnavailableError extends ApiError {
  constructor(options) {
    super({
      ...options,
      message: (options == null ? void 0 : options.message) ?? getBootstrapTranslations().WEB2_ERROR_503_TITLE_V2,
      code: ApiErrorCode.API_SERVICE_UNAVAILABLE_ERROR_503
    });
  }
}
class ApiTechnicalError extends ApiError {
  constructor(options) {
    super({
      ...options,
      message: (options == null ? void 0 : options.message) ?? getBootstrapTranslations().WEB2_TECHNICAL_ERROR,
      code: ApiErrorCode.API_TECHNICAL_ERROR
    });
  }
}
class ApiConnectionError extends ApiError {
  constructor(options) {
    super({
      ...options,
      logLevel: LogLevel.WARN,
      message: (options == null ? void 0 : options.message) ?? getBootstrapTranslations().WEB2_CONNECTION_ERROR_DESCRIPTION,
      code: ApiErrorCode.API_CONNECTION_ERROR
    });
  }
}
class GqlApiBatchedSubRequestClientError extends GqlApiError {
}
class GqlApiAccessDeniedError extends GqlApiBatchedSubRequestError {
  constructor(options) {
    super({
      ...options,
      code: ApiErrorCode.API_ACCESS_DENIED,
      message: getBootstrapTranslations().WEB2_SESSION_EXPIRED
    });
  }
}
function getRequestDescription(request) {
  return getObjectDescription({
    url: request.url,
    cache: request.cache,
    credentials: request.credentials,
    destination: request.destination,
    headers: [...request.headers.entries()].reduce((accumulator, [key, value]) => ({
      ...accumulator,
      [key]: value
    }), {}),
    integrity: request.integrity,
    keepalive: request.keepalive,
    method: request.method,
    redirect: request.redirect,
    referrer: request.referrer,
    referrerPolicy: request.referrerPolicy,
    body: request.body
  }, { prefix: "request " });
}
function resolveApi1Url(baseUrl = "") {
  if (process.env.NODE_ENV === "test") return "";
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    const { appOrigin, api1PublicUrl } = getCordovaAppConfig();
    return `${baseUrl || appOrigin}${api1PublicUrl}`;
  }
  if (process.env.VUE_APP_RENDERING_SSR) {
    return process.env.VUE_APP_API_1_INTERNAL_URL;
  }
  return process.env.VUE_APP_API_1_PUBLIC_URL;
}
class GqlApiBatchMaxSizeError extends GqlApiBatchError {
  constructor(options) {
    super({
      ...options,
      code: GqlApiErrorCode.API_TECHNICAL_ERROR
    });
    this.batchMaxSize = options.batchMaxSize;
  }
}
function sortGqlSubRequests(a, b) {
  const aPriority = a.getPriority();
  const bPriority = b.getPriority();
  if (aPriority > bPriority) {
    return -1;
  }
  if (bPriority > aPriority) {
    return 1;
  }
  if (a.mutation && !b.mutation) {
    return -1;
  }
  if (!a.mutation && b.mutation) {
    return 1;
  }
  if (a.created < b.created) {
    return -1;
  }
  if (b.created < a.created) {
    return 1;
  }
  return 0;
}
const _GqlBatchedRequest = class _GqlBatchedRequest {
  constructor() {
    this.mutations = [];
    this.queries = [];
    this.created = Date.now();
    this.id = _GqlBatchedRequest.createId();
    this.isLocked = false;
  }
  static createId() {
    _GqlBatchedRequest.autoIncrement += 1;
    return _GqlBatchedRequest.autoIncrement;
  }
  find({ id }) {
    return this.mutations.find((item) => item.id === id) ?? this.queries.find((item) => item.id === id);
  }
  getPromises() {
    return this.getGqlSubRequests().map(({ deferred }) => deferred.promise);
  }
  whenFulfilled() {
    return Promise.allSettled(this.getPromises()).then();
  }
  getGqlSubRequests() {
    return [
      ...this.mutations,
      ...this.queries
    ];
  }
  /**
   * Get sorted list of requests.
   * 1) Sort by priority
   * 2) Sort by query type (mutation or not)
   * 3) Sort by created.
   */
  getSortedGqlSubRequests() {
    return this.getGqlSubRequests().sort(sortGqlSubRequests);
  }
  /**
   * Сheck if all sub-requests have same priority
   */
  hasUniformPriority(priority) {
    return this.getGqlSubRequests().every((subRequest) => subRequest.getPriority() === priority);
  }
  update({
    queries,
    mutations
  }) {
    this.queries = queries ?? this.queries;
    this.mutations = mutations ?? this.mutations;
  }
  /**
   * @param {number} [size=Infinity] max size of new batch.
   * @param {function} [filter] filter batch items
   */
  getBatch({
    size = Infinity,
    doExtractBatch = false,
    filter = () => true
  }) {
    const usedHeaders = {};
    let batchTimeout = 0;
    const gqlSubRequests = this.getSortedGqlSubRequests().reduce((accumulator, gqlSubRequest) => {
      batchTimeout = Math.max(gqlSubRequest.getTimeout() ?? 0, batchTimeout);
      if (accumulator.length >= size) {
        return accumulator;
      }
      if (!filter(gqlSubRequest)) {
        return accumulator;
      }
      const headers = gqlSubRequest.getHeaders();
      const conflictingKeys = getConflictingKeys([usedHeaders, headers]);
      if (process.env.VUE_APP_ENV_DEV && conflictingKeys.length) {
        console.warn(
          `Skipping request <${gqlSubRequest.getName()}> for current batch because of conflicting header keys:`,
          ...conflictingKeys
        );
      }
      Object.assign(usedHeaders, headers);
      if (conflictingKeys.length === 0) {
        accumulator.push(gqlSubRequest);
      }
      return accumulator;
    }, []);
    if (doExtractBatch) {
      this.update({
        mutations: this.mutations.filter((gqlSubRequest) => !gqlSubRequests.includes(gqlSubRequest)),
        queries: this.queries.filter((gqlSubRequest) => !gqlSubRequests.includes(gqlSubRequest))
      });
    }
    const batch = new _GqlBatchedRequest();
    for (const gqlSubRequest of gqlSubRequests) {
      batch.addSubRequest(gqlSubRequest);
    }
    if (batchTimeout) {
      batch.setTimeout(batchTimeout);
    }
    return batch;
  }
  getOldestItem() {
    const item = this.getSortedGqlSubRequests()[0];
    assert(item, "Check batch size before calling");
    return item;
  }
  getNewestItem() {
    const item = this.getSortedGqlSubRequests().pop();
    assert(item, "Check batch size before calling");
    return item;
  }
  getMinCreated() {
    const item = this.getSortedGqlSubRequests()[0];
    assert(item);
    return item.created;
  }
  getMaxCreated() {
    const item = this.getSortedGqlSubRequests().pop();
    assert(item);
    return item.created;
  }
  addSubRequest(gqlSubRequest) {
    assert(
      !this.isLocked,
      `Batched graphql request is locked for sub-request instance <${gqlSubRequest.getName()}>`
    );
    assert(
      !this.getGqlSubRequests().includes(gqlSubRequest),
      `Batched graphql request already contains same sub-request instance <${gqlSubRequest.getName()}>`
    );
    if (gqlSubRequest.mutation) {
      this.update({
        mutations: [...this.mutations, gqlSubRequest]
      });
    } else {
      this.update({
        queries: [...this.queries, gqlSubRequest]
      });
    }
  }
  findCached(gqlSubRequest) {
    if (gqlSubRequest.mutation) {
      return this.mutations.find((cachedItem) => cachedItem.getCacheKey() === gqlSubRequest.getCacheKey());
    }
    return this.queries.find((cachedItem) => cachedItem.getCacheKey() === gqlSubRequest.getCacheKey());
  }
  size() {
    return this.queries.length + this.mutations.length;
  }
  getHeaders() {
    return this.getSortedGqlSubRequests().reduce((accumulator, gqlSubRequest) => {
      const headers = gqlSubRequest.getHeaders();
      if (process.env.VUE_APP_ENV_DEV) {
        const conflictingHeaders = getConflictingKeys([accumulator, headers]);
        if (conflictingHeaders.length) {
          console.warn(
            `Batch has conflicting headers within request <${gqlSubRequest.getName()}>:`,
            ...conflictingHeaders
          );
        }
      }
      return {
        ...accumulator,
        ...headers
      };
    }, {});
  }
  setTimeout(timeout) {
    this.timeout = timeout;
  }
  getTimeout() {
    return this.timeout;
  }
};
_GqlBatchedRequest.autoIncrement = 0;
let GqlBatchedRequest = _GqlBatchedRequest;
function isMutation(query) {
  return /\bmutations\b/.test(query);
}
function hashContent(content) {
  const hash = (crc32.str(content) >>> 0).toString(16).padStart(8, "0");
  return `${hash}-${content.length}`;
}
const hashContent$1 = /* @__PURE__ */ memoize(hashContent);
const _GqlBatchedSubRequest = class _GqlBatchedSubRequest {
  constructor({
    content,
    resolver,
    options,
    silent,
    group,
    headers = {},
    id,
    priority,
    timeout = 0,
    retry
  }) {
    this.ts = 0;
    this.failedAttempts = 0;
    this.options = {};
    this.priority = RequestOptionsPriority.NORMAL;
    this.getHeaders = memoize(() => Object.keys(this.headers).reduce((accumulator, headerKey) => {
      const headerValue = this.headers[headerKey];
      if (!headerValue) {
        return accumulator;
      }
      const headerKeyLowCase = headerKey.toLowerCase();
      accumulator[headerKeyLowCase] = headerValue;
      return accumulator;
    }, {}));
    this.getCacheKey = memoize(() => {
      const { ts, ...options2 } = this.options || {};
      const headers2 = this.getHeaders();
      const content2 = this.getContent();
      return JSON.stringify({
        customId: this.customId,
        content: content2,
        options: options2,
        headers: headers2
      });
    });
    this.getName = memoize(() => _GqlBatchedSubRequest.getCachedName(this.getContent()));
    this.priority = priority ?? this.priority;
    this.created = Date.now();
    this.content = content.trim();
    this.options = options ?? {};
    this.resolver = resolver;
    this.headers = Object.freeze(headers);
    this.silent = silent ?? false;
    this.mutation = isMutation(content);
    this.deferred = new Deferred();
    this.promise = this.deferred.promise;
    this.customId = id;
    this.id = id || getUuid();
    this.timeout = timeout;
    this.retry = retry;
    this.group = group;
  }
  setPriority(priority) {
    this.priority = priority;
  }
  getPriority() {
    return this.priority;
  }
  incrementFailedAttempts() {
    this.failedAttempts += 1;
  }
  updateFailedAttempts(value = 0) {
    this.failedAttempts = value;
  }
  getFailedAttempts() {
    return this.failedAttempts;
  }
  getContent() {
    return _GqlBatchedSubRequest.getCachedContent(this.content);
  }
  getContentHash() {
    return hashContent$1(this.getContent());
  }
  getOperationName() {
    const splittedRequestName = this.getName().split(":");
    return splittedRequestName[1] !== "unknown" ? splittedRequestName[1] : void 0;
  }
  getCacheTimestamp() {
    if (this.mutation) {
      return 0;
    }
    return this.ts;
  }
  setCacheTimestamp(ts) {
    assert(isNumber(ts), `Invalid number: ${ts}`);
    this.ts = ts;
  }
  isPossiblyCached() {
    if (this.mutation) {
      return false;
    }
    return this.getCacheTimestamp() > 0;
  }
  resetCacheTimestamp() {
    this.ts = 0;
  }
  toJSON() {
    return {
      id: this.id,
      customId: this.customId,
      priority: this.priority,
      ts: this.ts,
      created: this.created,
      failedAttempts: this.failedAttempts,
      options: this.options,
      name: this.getName(),
      headers: this.getHeaders(),
      timeout: this.timeout
    };
  }
  getTimeout() {
    return this.timeout;
  }
  getRetry() {
    return this.retry;
  }
  getGroup() {
    return this.group;
  }
};
_GqlBatchedSubRequest.getCachedContent = /* @__PURE__ */ memoize((content) => content.trim().replace(/\s{2,}/gm, " ").replace(/([\n\r])[\n\r]*\s+/gm, "$1"));
_GqlBatchedSubRequest.getCachedName = /* @__PURE__ */ memoize((content) => {
  const match = /(query|mutation)\s+(\w+)\b/m.exec(content);
  if (!match) {
    if (process.env.VUE_APP_ENV_DEV) {
      console.groupCollapsed("Incompatible graphql document syntax");
      console.log("Raw query content", content);
      console.groupEnd();
    }
    return "unknown:unknown";
  }
  return `${match[1]}:${match[2]}`;
});
let GqlBatchedSubRequest = _GqlBatchedSubRequest;
function getDefaultSettings() {
  return {
    cacheLength: 1e3,
    minAccumTime: 20,
    maxAccumTime: 40,
    maxBatchQueueSize: 6,
    maxRequestRetriesCount: 3,
    requestTimeout: 15e3
  };
}
class GqlApiCaptchaRequiredError extends GqlApiBatchedSubRequestError {
  constructor(options) {
    const { extensions } = options;
    super({
      ...options
    });
    this.extensions = extensions;
  }
}
let defaultErrorMessage;
const convertToGqlApiError = /* @__PURE__ */ Object.assign(
  (error, message = defaultErrorMessage) => {
    if (error instanceof ApiError) {
      return error;
    }
    return new GqlApiError({
      message,
      originalError: normalizeError(error)
    });
  },
  {
    setDefaultErrorMessage: (message) => {
      defaultErrorMessage = message;
    }
  }
);
class ApiIpBlockedError extends ApiError {
  constructor(options) {
    super({
      ...options,
      message: (options == null ? void 0 : options.message) ?? getBootstrapTranslations().WEB2_IP_BLOCKED_ERROR,
      code: ApiErrorCode.API_IP_BLOCKED_ERROR
    });
  }
}
class GqlApiServiceSuspendedError extends GqlApiBatchedSubRequestError {
  constructor(options) {
    super({
      ...options
    });
  }
}
class GqlApiCustomerHistoryLimitExceededError extends GqlApiBatchedSubRequestError {
  constructor(options) {
    super({
      ...options
    });
  }
}
function getUrl(fullUrl, query) {
  if (process.env.NODE_ENV === "development") {
    const hasOriginalQuery = fullUrl.includes("?");
    const qIndex = fullUrl.indexOf("?");
    const originalQuery = hasOriginalQuery ? fullUrl.slice(qIndex) : "";
    if (!query || !originalQuery) return fullUrl;
    const finalQuery = mergeQueries(originalQuery, query);
    const hasQueryAfterMerge = trim(finalQuery, "?").length;
    if (hasQueryAfterMerge) {
      const fullUrlWithoutQuery = fullUrl.replace(/\?.+$/, "");
      return `${fullUrlWithoutQuery}?${finalQuery}`;
    }
  }
  return fullUrl;
}
const gqlCache = /* @__PURE__ */ new Map();
function cleanGqlFragmentDuplication(gqlDocument) {
  if (!gqlDocument.includes("fragment")) return gqlDocument;
  const cache = gqlCache.get(gqlDocument);
  if (cache !== void 0) return cache;
  const trimmedOutput = gqlDocument.trim();
  const parts = trimmedOutput.split(/\bfragment /).map((str) => str.trim()).filter(Boolean);
  const hasDocument = trimmedOutput.match(/^(query|mutation) /);
  const [query, ...fragments] = hasDocument ? parts : ["", ...parts];
  const result = query + fragments.map((fragment, index, list) => {
    if (list.indexOf(fragment) === index) {
      return `fragment ${fragment}`;
    }
    return "";
  }).join("\n");
  gqlCache.set(gqlDocument, result);
  return result;
}
function isServerApiResponseBaseError(errorData) {
  return isObject$3(errorData) && isString(errorData.errorCode) && isOptionalString(errorData.message);
}
function isServerApiErrorResponse(errorData) {
  return isServerApiResponseBaseError(errorData);
}
function createLogNamespace(namespace) {
  return logger.createNamespace(namespace);
}
function isRetryableApiError(error, isMutation2) {
  if (error instanceof ApiConnectionError || error instanceof GqlApiBatchUnknownHashError || error instanceof GqlApiBatchHashingDisabledError) {
    return true;
  }
  return !isMutation2 && (error instanceof ApiTechnicalError || error instanceof ApiServiceUnavailableError);
}
function isIpBlockedError(errorData) {
  return isServerApiErrorResponse(errorData) && ApiErrorCode.API_IP_BLOCKED_ERROR.equals(errorData.errorCode);
}
function isServerApiUnknownHashError(error) {
  return isServerApiErrorResponse(error) && ApiErrorCode.API_UNKNOWN_HASH.equals(error.errorCode) && isString(error.requestId);
}
function isServerApiHashingDisabled(error) {
  return isServerApiErrorResponse(error) && ApiErrorCode.API_REQUEST_HASHING_IS_DISABLED.equals(error.errorCode);
}
function isServerApiResponseInvalidBatchSizeError(errorData) {
  return isServerApiErrorResponse(errorData) && errorData.errorCode === "INVALID_REQUESTS_NUMBER" && isNumber(errorData.maxSize);
}
function isServerApiSubResponseError(item) {
  return isObject$3(item) && isOptionalString(item.message) && (item.path === void 0 || isArrayOfStrings(item.path)) && (isObject$3(item.extensions) && isString(item.extensions.errorCode) && isOptionalString(item.extensions.classification) && isOptionalString(item.extensions.message)) && (item.locations === void 0 || Array.isArray(item.locations) && item.locations.every((loc) => isValidObject(loc, {
    line: [isNumber],
    column: [isNumber]
  })));
}
function isServerApiFailedSubResponse(value) {
  return isObject$3(value) && (value.data === null || value.data === void 0) && Array.isArray(value.errors) && isArrayOf(isServerApiSubResponseError, value.errors);
}
function isServerApiSubResponseMutationResultNode(item) {
  return isObject$3(item) && item.data !== void 0;
}
function isServerApiSubResponseQueryResultNode(item) {
  return isObject$3(item) && item.data !== void 0 && isNumber(item.ts);
}
function isServerApiSubResponseResultNode(value) {
  return isServerApiSubResponseQueryResultNode(value) || isServerApiSubResponseMutationResultNode(value);
}
function getCommonHeaders() {
  return {
    "content-type": "application/json"
  };
}
function isServerApiOkSubResponse(value) {
  return isObject$3(value) && isObject$3(value.data);
}
function isServerApiSubResponse(value) {
  return isServerApiOkSubResponse(value) || isServerApiFailedSubResponse(value);
}
function isServerApiBatchedResponse(argument) {
  return isObject$3(argument) && Object.entries(argument).every(([, item]) => isServerApiSubResponse(item));
}
class GqlBatchedClient extends BaseClient {
  constructor(options = {}) {
    super({
      baseUrl: resolveApi1Url(),
      method: "POST",
      ...options,
      headers: {
        ...getCommonHeaders(),
        ...getBaseHeaders(),
        ...options == null ? void 0 : options.headers
      }
    });
    this.logger = createLogNamespace("BatchedGraphqlClient");
    this.responseNodeCache = null;
    this.settings = getDefaultSettings();
    this.defaultMaxAccumTime = 40;
    this.defaultMinAccumTime = 20;
    this.requestNumber = 1;
    this.maxMutationsPerBatch = 1;
    this.abortSignals = /* @__PURE__ */ new WeakMap();
    this.isQueryHashingStateEnabled = true;
    this.pendingBatch = new GqlBatchedRequest();
    this.sentBatches = [];
    this.updateTimer = debounce$1(() => {
      if (!this.isMutationInProcess()) {
        if (this.nextCheckTimer) {
          clearInterval(this.nextCheckTimer);
          this.nextCheckTimer = void 0;
        }
        const nextBatchSize = this.getNextBatch().size();
        if (nextBatchSize === 0) {
          return;
        }
        const { maxBatchQueueSize } = this;
        assert(nextBatchSize <= maxBatchQueueSize);
        const isFilledBatch = nextBatchSize === maxBatchQueueSize;
        const now2 = Date.now();
        const nextCallTimestamp = isFilledBatch ? now2 : Math.max(
          now2,
          // Batch default close time.
          Math.min(
            this.pendingBatch.getNewestItem().created + this.getMaxAccumTime(),
            this.pendingBatch.getOldestItem().created + this.getMinAccumTime()
          )
        );
        const timeout = nextCallTimestamp - now2;
        this.nextCheckTimer = setTimeout(() => {
          this.nextCheckTimer = void 0;
          this.handlePendingBatch().then(() => {
            const hasTimer = !!this.nextCheckTimer;
            if (!hasTimer && this.getNextBatch().size() > 0) {
              this.updateTimer();
            }
          }, (error) => {
            this.logError(convertToGqlApiError(error, "handlePendingBatch error"));
          });
        }, timeout);
      }
    }, 5, { maxWait: 10 });
    this.middlewares = /* @__PURE__ */ new Map();
    this.errorsCache = /* @__PURE__ */ new WeakSet();
  }
  setupSettings(settings) {
    this.settings = {
      ...this.settings,
      ...settings
    };
  }
  setMaxMutationsPerBatch(count) {
    this.maxMutationsPerBatch = count;
  }
  setMaxBatchQueueSize(size) {
    this.settings.maxBatchQueueSize = size;
    this.debug("max batch queue size has been changed to %s", size);
    this.updateTimer();
  }
  isQueryHashingEnabled() {
    return this.isQueryHashingStateEnabled && getBuiltinConfig().isQueryHashingEnabled;
  }
  toggleQueryHashingState(state) {
    this.isQueryHashingStateEnabled = state;
  }
  getMaxAccumTime() {
    return this.settings.maxAccumTime ?? this.defaultMaxAccumTime;
  }
  getMinAccumTime() {
    return this.settings.minAccumTime ?? this.defaultMinAccumTime;
  }
  get maxBatchQueueSize() {
    return this.settings.maxBatchQueueSize ?? 0;
  }
  get requestTimeout() {
    return this.settings.requestTimeout || 15 * 1e3 + 1e3;
  }
  get maxRequestRetriesCount() {
    return this.settings.maxRequestRetriesCount || 1;
  }
  logError(error) {
    if (!this.settings.silentErrors) {
      this.logger.error(error);
    }
  }
  getSentBatchesCount() {
    return this.sentBatches.length;
  }
  getAllBatches() {
    return [
      this.pendingBatch,
      ...this.sentBatches
    ];
  }
  findSameGqlSubRequest(item) {
    const allBatches = this.getAllBatches();
    for (let index = 0, { length } = allBatches; index < length; index += 1) {
      const cachedItem = allBatches[index].findCached(item);
      if (cachedItem) {
        return cachedItem;
      }
    }
    return void 0;
  }
  async handlePendingBatch() {
    this.debug("prepare to handle next batch");
    const nextBatch = this.getNextBatch({ doExtractBatch: true });
    this.debug(`next batch size=${nextBatch.size()}`);
    if (nextBatch.size() > 0) {
      this.sentBatches.push(nextBatch);
      try {
        await this.doBatchedRequest(nextBatch);
      } finally {
        this.removeBatchFromSent(nextBatch);
      }
    } else {
      this.debug("unable to handle empty pending batch");
    }
  }
  removeBatchFromSent(batch) {
    this.sentBatches = this.sentBatches.filter((sentBatch) => sentBatch !== batch);
  }
  addMiddleware(method, middleware) {
    const set = this.middlewares.get(method) ?? /* @__PURE__ */ new Set();
    this.middlewares.set(method, set.add(middleware));
    return () => {
      var _a;
      (_a = this.middlewares.get(method)) == null ? void 0 : _a.delete(middleware);
    };
  }
  applyMiddlewares({ method, variables }) {
    let context = { method, variables };
    if (context.variables) {
      for (const middleware of this.middlewares.get(method) ?? []) {
        try {
          const result = middleware(context);
          if (result !== void 0) {
            const resultVariables = result.variables;
            if (resultVariables) {
              context = {
                variables: resultVariables,
                method
              };
            }
          }
        } catch (err) {
          console.error("Unable to apply middleware", normalizeError(err), method);
        }
      }
    }
    return context;
  }
  async requestGraphql(query, variables, callback, { cacheTTL = this.defaultCacheTTL, ...requestOptions }, method) {
    const context = this.applyMiddlewares({ method, variables });
    const subRequest = new GqlBatchedSubRequest({
      content: cleanGqlFragmentDuplication(query),
      options: context.variables.options,
      resolver: callback,
      id: requestOptions == null ? void 0 : requestOptions.id,
      silent: requestOptions == null ? void 0 : requestOptions.silent,
      headers: requestOptions == null ? void 0 : requestOptions.headers,
      priority: (requestOptions == null ? void 0 : requestOptions.priority) ?? RequestOptionsPriority.NORMAL,
      timeout: requestOptions == null ? void 0 : requestOptions.timeout,
      retry: requestOptions == null ? void 0 : requestOptions.retry,
      group: requestOptions == null ? void 0 : requestOptions.group
    });
    if (cacheTTL) {
      const cacheItem = await this.getCache(subRequest.getCacheKey());
      if (cacheItem) return cacheItem;
    }
    const sameGqlSubRequest = this.findSameGqlSubRequest(subRequest);
    if (sameGqlSubRequest) {
      return sameGqlSubRequest.promise;
    }
    const cachedResponseNode = this.getFromCache(subRequest.getCacheKey());
    if (cachedResponseNode) {
      subRequest.setCacheTimestamp(cachedResponseNode.ts);
    }
    this.pendingBatch.addSubRequest(subRequest);
    this.updateTimer();
    try {
      const result = await subRequest.deferred.promise;
      if (cacheTTL) {
        void this.setCache(subRequest.getCacheKey(), result, cacheTTL);
      }
      return result;
    } catch (error) {
      let apiError = convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR);
      const hasError = this.errorsCache.has(apiError);
      if (hasError) {
        apiError = apiError.clone();
      } else {
        this.errorsCache.add(apiError);
      }
      apiError.setOperationName(subRequest.getOperationName());
      throw Object.assign(apiError, { silent: subRequest.silent });
    }
  }
  saveToCache(cacheKey, node) {
    var _a;
    return (_a = this.responseNodeCache) == null ? void 0 : _a.set(cacheKey, JSON.parse(JSON.stringify(node)));
  }
  removeCache(cacheKey) {
    var _a;
    (_a = this.responseNodeCache) == null ? void 0 : _a.remove(cacheKey);
  }
  getFromCache(cacheKey) {
    var _a;
    return (_a = this.responseNodeCache) == null ? void 0 : _a.get(cacheKey);
  }
  getRequestData(batch) {
    const requests = [];
    for (const item of [...batch.mutations, ...batch.queries]) {
      let ts = item.getCacheTimestamp();
      const cacheItem = this.getFromCache(item.getCacheKey());
      if (cacheItem == null ? void 0 : cacheItem.ts) {
        ts = cacheItem.ts;
      }
      const requestItem = {
        id: item.id,
        query: this.isQueryHashingEnabled() ? void 0 : item.getContent(),
        qKey: this.isQueryHashingEnabled() ? item.getContentHash() : void 0,
        operationName: item.getOperationName(),
        variables: {
          options: item.mutation ? item.options : {
            ...item.options,
            // Client cache timestamp, to tell server.
            ts
          }
        }
      };
      requests.push(requestItem);
    }
    return requests;
  }
  failBatchedRequest(batch, batchApiError) {
    for (const gqlSubRequest of batch.getSortedGqlSubRequests()) {
      void Promise.resolve().then(() => {
        this.handleFailedBatchedSubRequest(
          gqlSubRequest,
          batchApiError
        );
      });
    }
  }
  // TODO: replace to service
  // eslint-disable-next-line consistent-return,sonarjs/cognitive-complexity
  async doBatchedRequest(batch) {
    const requestData = this.getRequestData(batch);
    try {
      const controller = new AbortController();
      this.abortSignals.set(batch, controller);
      const initOptions = {};
      if (batch.hasUniformPriority(RequestOptionsPriority.LOW)) {
        initOptions.priority = "low";
      }
      if (batch.hasUniformPriority(RequestOptionsPriority.HIGH)) {
        initOptions.priority = "high";
      }
      const responseData = await this.request({
        abortController: controller,
        endpoint: "/",
        data: requestData,
        headers: batch.getHeaders(),
        timeout: batch.getTimeout(),
        query: {
          ops: batch.getGqlSubRequests().map((req) => req.getOperationName()).join(",")
        },
        initOptions
      });
      if (isServerApiBatchedResponse(responseData)) {
        try {
          this.handleResponseData(batch, responseData);
        } catch (error) {
          this.failBatchedRequest(batch, convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR));
        }
      } else if (isServerApiErrorResponse(responseData)) {
        if (isServerApiHashingDisabled(responseData) || isServerApiUnknownHashError(responseData)) {
          this.toggleQueryHashingState(false);
          const err = isServerApiUnknownHashError(responseData) ? new GqlApiBatchUnknownHashError({
            requestId: responseData.requestId,
            batch
          }) : new GqlApiBatchHashingDisabledError({ message: responseData.message });
          this.failBatchedRequest(
            batch,
            err
          );
        } else if (isServerApiResponseInvalidBatchSizeError(responseData)) {
          this.setMaxBatchQueueSize(responseData.maxSize);
          this.failBatchedRequest(
            batch,
            new GqlApiBatchMaxSizeError({
              batchMaxSize: this.maxBatchQueueSize,
              batch
            })
          );
        } else if (isIpBlockedError(responseData)) {
          this.failBatchedRequest(
            batch,
            new ApiIpBlockedError()
          );
        } else {
          this.failBatchedRequest(
            batch,
            new GqlApiBatchError({
              code: GqlApiErrorCode.API_TECHNICAL_ERROR,
              batch
            })
          );
        }
      } else {
        this.failBatchedRequest(
          batch,
          new GqlApiBatchError({ batch })
        );
      }
    } catch (error) {
      this.removeBatchFromSent(batch);
      this.failBatchedRequest(batch, convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR));
    } finally {
      this.abortSignals.delete(batch);
    }
  }
  handleBatchedSubRequest(subRequest, batch, responseItem) {
    let responseNode;
    let responseNodeTs = 0;
    try {
      responseNode = subRequest.resolver(responseItem.data);
      responseNodeTs = responseNode.ts;
      assert(isServerApiSubResponseResultNode(responseNode));
    } catch (rawError) {
      this.handleFailedBatchedSubRequest(subRequest, new GqlApiBatchedSubRequestClientError({
        batch,
        subRequest,
        responseItem,
        originalError: normalizeError(rawError)
      }));
      return;
    }
    if (!subRequest.mutation) {
      const isCacheUsed = responseNodeTs > 0;
      const cacheKey = subRequest.getCacheKey();
      const isCacheRequiredByServer = isCacheUsed && responseNode.data === null && subRequest.getCacheTimestamp() === responseNodeTs;
      if (isCacheRequiredByServer) {
        const cachedNode = this.getFromCache(cacheKey);
        if (!cachedNode) {
          this.removeCache(cacheKey);
          subRequest.resetCacheTimestamp();
          this.pendingBatch.addSubRequest(subRequest);
          this.updateTimer();
          return;
        }
        Object.assign(responseNode, {
          data: JSON.parse(JSON.stringify(cachedNode.data))
        });
      } else if (isCacheUsed) {
        this.saveToCache(cacheKey, responseNode);
      }
    }
    subRequest.deferred.resolve(responseNode.data);
  }
  handleResponseData(batch, subRequestResponses) {
    const sortedList = batch.getSortedGqlSubRequests();
    sortedList.reduce((accumulator, subRequest) => {
      var _a;
      const subRequestResponse = subRequestResponses[subRequest.id];
      if (isServerApiFailedSubResponse(subRequestResponse)) {
        const { errors: [error] } = subRequestResponse;
        assert(error.extensions, "Unable to get gql error extensions");
        assert(error, "Unable to handle sub request error");
        if (((_a = error.extensions) == null ? void 0 : _a.classification) === "ValidationError") {
          assert(error.extensions.errorCode, "errorCode is required");
          Object.assign(error.extensions, {
            errorCode: GqlApiResponseErrorCode.GQL_API_SERVICE_VALIDATION_ERROR
          });
        }
        const errorCode = error.extensions.errorCode || GqlApiResponseErrorCode.API_TECHNICAL_ERROR;
        assert(isString(errorCode), "Expected errorCode to be as string");
        const message = error.extensions.message || this.bootstrapTranslations.WEB2_TECHNICAL_ERROR;
        assert(isString(message), "Expected errorMessage to be as string");
        if (errorCode === InvalidCodeExceptionCode.INVALID_CODE) {
          assert(isNumber(error.extensions.code), "Expected code to be as number");
          if ([502, 503].includes(error.extensions.code)) {
            this.handleFailedBatchedSubRequest(subRequest, new ApiServiceUnavailableError());
          } else {
            this.handleFailedBatchedSubRequest(subRequest, new ApiTechnicalError());
          }
          return accumulator;
        }
        let GqlApiErrorConstructor;
        switch (errorCode) {
          case G2SVRequiredExceptionCode.G2SV_REQUIRED:
            GqlApiErrorConstructor = GqlApiG2svRequiredError;
            break;
          case RemoteApiErrorExceptionCode.INVALID_CAPTCHA:
          case RemoteApiErrorExceptionCode.CAPTCHA_NEEDED:
            GqlApiErrorConstructor = GqlApiCaptchaRequiredError;
            break;
          case RemoteApiErrorExceptionCode.SERVICE_SUSPENDED:
            GqlApiErrorConstructor = GqlApiServiceSuspendedError;
            break;
          case RemoteApiErrorExceptionCode.PROMOTION_NOT_FOUND:
            GqlApiErrorConstructor = GqlApiPromotionNotFoundError;
            break;
          case RemoteApiErrorExceptionCode.CUSTOMER_HISTORY_LIMIT_EXCEEDED:
            GqlApiErrorConstructor = GqlApiCustomerHistoryLimitExceededError;
            break;
          case AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED:
          case SessionExceptionCode.SESSION:
            GqlApiErrorConstructor = GqlApiAccessDeniedError;
            break;
          case PreviousRequestHasNotExpiredExceptionCode.PREVIOUS_REQUEST_HAS_NOT_EXPIRED:
            GqlApiErrorConstructor = GqlApiPreviousRequestHasNotExpired;
            break;
          default:
            GqlApiErrorConstructor = GqlApiBatchedSubRequestError;
            break;
        }
        const apiError = new GqlApiErrorConstructor({
          message,
          batch,
          subRequest,
          // TODO: Refactor after LEONAPI-552
          extensions: {
            ...error.extensions,
            errorCode,
            message
          }
        });
        this.handleFailedBatchedSubRequest(subRequest, apiError);
      } else if (isServerApiSubResponseResultNode(subRequestResponse)) {
        this.handleBatchedSubRequest(
          subRequest,
          batch,
          subRequestResponse
        );
        return accumulator + 1;
      } else {
        this.handleFailedBatchedSubRequest(subRequest, new GqlApiBatchError({
          batch,
          subRequest
        }));
      }
      return accumulator;
    }, 0);
  }
  async getResponse(url, options, $return = { request: void 0 }, controller, timeout) {
    return doFetch(
      url,
      options,
      timeout ?? this.requestTimeout,
      (originalError, request) => new ApiConnectionError({ request, originalError }),
      controller,
      (request) => {
        Object.assign($return, { request });
      }
    );
  }
  async request(options) {
    const {
      endpoint,
      data,
      headers,
      abortController,
      timeout,
      query = {},
      initOptions = {}
    } = options;
    assert(data, "Unable to start request without data.");
    const fullUrl = `${this.getOrigin()}${this.getBaseUrl()}${endpoint}`.replace(/\/+$/, "");
    const requestInit = {
      body: null,
      headers: this.getHeaders(headers),
      method: this.getDefaultMethod(),
      credentials: this.getCredentials(),
      ...initOptions
    };
    try {
      requestInit.body = JSON.stringify(data);
      const $return = { request: void 0 };
      const url = getUrl(fullUrl, query);
      const response = await this.getResponse(url, requestInit, $return, abortController, timeout);
      const { request } = $return;
      const clonedResponse = process.env.VUE_APP_PRERENDER ? response.clone() : void 0;
      try {
        return await response.json();
      } catch (error) {
        assert(error instanceof SyntaxError, "Unable to parse response body");
        const apiError = [502, 503].includes(response.status) ? new ApiServiceUnavailableError({ request }) : new ApiTechnicalError({ originalError: error, request });
        if (request) {
          apiError.addLogMetaData("request", Object.assign(response, {
            toJSON: () => getRequestDescription(request)
          }));
        }
        apiError.addLogMetaData("response", Object.assign(response, {
          toJSON: () => getResponseDescription(response)
        }));
        if (process.env.VUE_APP_PRERENDER) {
          console.info(`[Graphql] Parse response error - status: ${response.status}`, error);
          try {
            const responseText = await (clonedResponse == null ? void 0 : clonedResponse.text());
            console.info(`[Graphql] Response text ${responseText}`);
          } catch (bodyParseError) {
            console.info("[Graphql] Body parse error", bodyParseError);
          }
        }
        return await Promise.reject(apiError);
      }
    } catch (error) {
      if (process.env.VUE_APP_PRERENDER) {
        console.info("[Graphql] Request error", error);
      }
      throw convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR);
    }
  }
  abortBatch(batch) {
    const controller = this.abortSignals.get(batch);
    const batchName = `batch#${batch.id}`;
    assert(controller, `Unable to stop request for ${batchName}`);
    controller.abort();
  }
  clearQueue() {
    for (const batch of this.sentBatches) {
      this.abortBatch(batch);
    }
    this.pendingBatch = new GqlBatchedRequest();
  }
  setAccumTime(n) {
    assert(n >= 0 && Number.isFinite(n));
    this.settings.maxAccumTime = n;
  }
  resetAccumTime() {
    this.setAccumTime(this.defaultMaxAccumTime);
  }
  isMutationInProcess() {
    return this.sentBatches.slice(0).some((batch) => batch.mutations.length > 0);
  }
  async whenAllSentMutationsProcessed() {
    await Promise.all(
      this.sentBatches.filter((batch) => batch.mutations.length > 0).map((batch) => batch.whenFulfilled())
    );
  }
  getNextBatch(options) {
    const doExtractBatch = (options == null ? void 0 : options.doExtractBatch) ?? false;
    const size = (options == null ? void 0 : options.size) ?? this.maxBatchQueueSize;
    const firstSentBatch = this.sentBatches[0];
    assert(
      !this.isMutationInProcess(),
      "Expected mutations only within first batch"
    );
    const sentBatchHasMutation = !!(firstSentBatch == null ? void 0 : firstSentBatch.mutations.length);
    let { maxMutationsPerBatch: leftMutations } = this;
    let firstGqlRequestInQueue;
    return this.pendingBatch.getBatch({
      size,
      doExtractBatch,
      filter: (gqlRequest) => {
        if (gqlRequest.mutation) {
          if (sentBatchHasMutation || leftMutations <= 0) return false;
          leftMutations -= 1;
        }
        if (!firstGqlRequestInQueue) {
          firstGqlRequestInQueue = gqlRequest;
          return true;
        }
        return firstGqlRequestInQueue.getGroup() === gqlRequest.getGroup();
      }
    });
  }
  /**
   * Flush pending requests. In parallel mode, we handle all pending batches at once.
   * IN serial mode, we handle available batches one by one.
   *
   * @param inParallel
   */
  async flush({ inParallel = false } = {}) {
    this.debug("flush");
    const gqlRequests = this.pendingBatch.getGqlSubRequests();
    for (const gqlRequest of gqlRequests) {
      gqlRequest.setPriority(RequestOptionsPriority.HIGH);
    }
    await this.whenAllSentMutationsProcessed();
    if (inParallel) {
      const promises = [];
      do {
        promises.push(this.handlePendingBatch());
      } while (this.getNextBatch({ doExtractBatch: false }).size() > 0);
      await Promise.allSettled(promises);
      return Promise.resolve();
    }
    await Promise.allSettled([this.handlePendingBatch()]);
    const nextBatch = this.getNextBatch({ doExtractBatch: false });
    return nextBatch.size() > 0 ? this.flush() : Promise.resolve();
  }
  handleFailedBatchedSubRequest(item, error) {
    const failedAttempts = item.getFailedAttempts();
    const maxRequestRetriesCount = item.getRetry() ?? this.maxRequestRetriesCount;
    if (isRetryableApiError(error, item.mutation) && failedAttempts < maxRequestRetriesCount) {
      item.incrementFailedAttempts();
      this.pendingBatch.addSubRequest(item);
      this.updateTimer();
    } else {
      this.removeCache(item.getCacheKey());
      error.addLogMetaData("retries", failedAttempts);
      item.deferred.reject(error);
    }
  }
}
class ApiRequestAbortedError extends ApiError {
  constructor(options) {
    super({
      ...options,
      message: (options == null ? void 0 : options.message) ?? getBootstrapTranslations().WEB2_CONNECTION_ERROR_DESCRIPTION,
      code: ApiErrorCode.API_REQUEST_ABORTED_ERROR
    });
  }
}
function isResponseApiError(value) {
  return isObject$3(value) && isString(value.message) && isString(value.errorCode) && isOptionalNumber(value.code);
}
class RestClient extends BaseClient {
  constructor(options) {
    super(options);
  }
  getRequestInit(data, method, headers, notAddBaseHeaders, priority) {
    let requestPriority = "auto";
    if (priority === RequestOptionsPriority.LOW) {
      requestPriority = "low";
    } else if (priority === RequestOptionsPriority.HIGH) {
      requestPriority = "high";
    }
    const requestInit = {
      headers: notAddBaseHeaders ? headers ?? {} : { ...this.getHeaders(), ...this.getCustomHeader(), ...headers },
      method: method ?? this.getDefaultMethod(),
      credentials: this.getCredentials(),
      priority: requestPriority
    };
    if (data) {
      const body = data instanceof FormData || data instanceof URLSearchParams ? data : JSON.stringify(data);
      if (requestInit.method !== "GET" && body) {
        requestInit.body = body;
      }
    }
    return requestInit;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  async request({
    endpoint,
    guard,
    guardError,
    data,
    query,
    abortController,
    method,
    silent,
    requestTimeout,
    headers,
    notAddBaseHeaders,
    cacheTTL = this.defaultCacheTTL
  }) {
    const queryIndex = endpoint.indexOf("?");
    let fullQuery = "";
    let path = endpoint;
    if (queryIndex > -1) {
      path = endpoint.slice(0, queryIndex);
      fullQuery = endpoint.slice(queryIndex);
    }
    if (typeof query === "string" || typeof query === "object" && query !== null) {
      fullQuery = mergeQueries(fullQuery, query);
    }
    const requestInit = this.getRequestInit(data, method, headers, notAddBaseHeaders);
    const url = `${this.getOrigin()}${this.getBaseUrl()}${path}${fullQuery}`;
    if (cacheTTL) {
      const cacheKey = JSON.stringify([url, requestInit]);
      const cacheItem = await this.getCache(cacheKey);
      if (cacheItem) return cacheItem;
    }
    try {
      const response = await doFetch(
        url,
        requestInit,
        requestTimeout,
        (originalError, request) => new ApiConnectionError({ originalError, silent, request }),
        abortController
      );
      if (response.ok) {
        const contentType = response.headers.get("content-type");
        let result;
        if (contentType == null ? void 0 : contentType.startsWith("text/plain")) {
          result = await response.text();
        } else {
          result = await response.json();
        }
        const apiError2 = this.handleApiError(result, `${path}${fullQuery}`, Boolean(silent));
        if (apiError2) {
          return Promise.reject(apiError2);
        }
        if (guard(result)) {
          if (cacheTTL) {
            const cacheKey = JSON.stringify([url, requestInit]);
            void this.setCache(cacheKey, result, cacheTTL);
          }
          return result;
        }
        if (!(guardError == null ? void 0 : guardError(result))) {
          logger.error(`Response guard failed: url=${url}; response=${Json.stringify(
            result,
            { defaultValue: "Unable to parse response" }
          )}`);
        }
        return Promise.reject(new ApiTechnicalError({ silent }));
      }
      if (response.status === 502 || response.status === 503) {
        return Promise.reject(new ApiServiceUnavailableError({ silent }));
      }
      const badResult = await response.json();
      const apiError = this.handleApiError(badResult, `${path}${fullQuery}`, Boolean(silent));
      if (apiError) {
        return Promise.reject(apiError);
      }
      if (badResult.message || badResult.errorCode) {
        return Promise.reject(
          new ApiError({
            message: badResult.message || "",
            code: new ApiErrorCode(badResult.errorCode || ApiErrorCode.API_UNEXPECTED_ERROR.toString()),
            silent
          })
        );
      }
      return Promise.reject(new ApiConnectionError({ silent, response }));
    } catch (rawError) {
      return Promise.reject(
        (abortController == null ? void 0 : abortController.signal.aborted) ? new ApiRequestAbortedError({
          silent,
          operationName: url,
          originalError: normalizeError(rawError)
        }) : new ApiConnectionError({
          silent,
          operationName: url,
          originalError: normalizeError(rawError)
        })
      );
    }
  }
  // eslint-disable-next-line rulesdir/class-method-use-this-regex,class-methods-use-this
  handleApiError(result, path, silent) {
    if (isResponseApiError(result)) {
      if (ApiErrorCode.API_IP_BLOCKED_ERROR.equals(result.errorCode)) {
        return new ApiIpBlockedError();
      }
      if (result.code === 502 || result.code === 503) {
        return new ApiServiceUnavailableError();
      }
      return result.errorCode ? new ApiError({
        message: result.message || getBootstrapTranslations().WEB2_CONNECTION_ERROR_DESCRIPTION,
        code: new ApiErrorCode(result.errorCode),
        responseCode: result.code,
        operationName: path,
        silent
      }) : new ApiTechnicalError({ silent });
    }
    return null;
  }
}
const RequestGroupScore24 = "s24";
function isGeneralApiError(error) {
  return error instanceof ChunkLoadError || error instanceof ApiConnectionError || error instanceof ApiServiceUnavailableError || error instanceof ApiRequestAbortedError || error instanceof GqlApiAccessDeniedError;
}
var DocumentType = /* @__PURE__ */ ((DocumentType2) => {
  DocumentType2["PASSPORT"] = "PASSPORT";
  DocumentType2["RESIDENCE"] = "RESIDENCE";
  DocumentType2["THIRD_PAGE"] = "THIRD_PAGE";
  DocumentType2["FACEPASS"] = "FACEPASS";
  DocumentType2["VIDEO"] = "VIDEO";
  return DocumentType2;
})(DocumentType || {});
function resolveApi2Url(baseUrl = "") {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    const { appOrigin, api2PublicUrl } = getCordovaAppConfig();
    return `${baseUrl || appOrigin}${api2PublicUrl}`;
  }
  if (process.env.VUE_APP_RENDERING_SSR) {
    return process.env.VUE_APP_API_2_INTERNAL_URL;
  }
  return process.env.VUE_APP_API_2_PUBLIC_URL;
}
function resolveApi3Url(baseUrl = "") {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    const { appOrigin, api3PublicUrl } = getCordovaAppConfig();
    return `${baseUrl || appOrigin}${api3PublicUrl}`;
  }
  if (process.env.VUE_APP_RENDERING_SSR) {
    return process.env.VUE_APP_API_3_INTERNAL_URL;
  }
  return process.env.VUE_APP_API_3_PUBLIC_URL;
}
class MockedApiRequestResolver {
  constructor(resolveOperation) {
    this.resolveOperation = resolveOperation;
    this.requestDelay = 200;
  }
  resolve(operationName, option) {
    return this.resolveOperation(operationName, option);
  }
}
class MockedGqlBatchedClient extends GqlBatchedClient {
  constructor(options) {
    super();
    this.resolver = (options == null ? void 0 : options.resolver) ?? null;
  }
  async request(options) {
    var _a;
    const response = options.data.reduce((result, option) => {
      var _a2;
      const resolvedData = (_a2 = this.resolver) == null ? void 0 : _a2.resolve(option.operationName, option);
      if (!resolvedData) {
        return result;
      }
      const subResponse = resolvedData.errors ? {
        data: null,
        errors: resolvedData.errors
      } : {
        data: {
          queries: resolvedData == null ? void 0 : resolvedData.queries,
          mutations: resolvedData == null ? void 0 : resolvedData.mutations
        }
      };
      return {
        ...result,
        [option.id]: subResponse
      };
    }, {});
    await sleep(((_a = this.resolver) == null ? void 0 : _a.requestDelay) || 0);
    return Promise.resolve(response);
  }
}
export {
  ApiConnectionError,
  ApiError,
  ApiErrorCode,
  ApiIpBlockedError,
  ApiRequestAbortedError,
  ApiServiceUnavailableError,
  ApiTechnicalError,
  DocumentType,
  GqlApiAccessDeniedError,
  GqlApiBatchedSubRequestError,
  GqlApiCaptchaRequiredError,
  GqlApiCustomerHistoryLimitExceededError,
  GqlApiError,
  GqlApiPreviousRequestHasNotExpired,
  GqlApiPromotionNotFoundError,
  GqlApiResponseErrorCode,
  GqlApiServiceSuspendedError,
  GqlBatchedClient,
  MockedApiRequestResolver,
  MockedGqlBatchedClient,
  RequestGroupScore24,
  RestClient,
  getBaseHeaders,
  isGeneralApiError,
  languageHeaderName,
  resolveApi1Url,
  resolveApi2Url,
  resolveApi3Url,
  themeHeaderName
};
